import {MenuContainer, MenuWrapper} from "./styles";
import {MenuItem} from "./components/MenuItem";
import {ReactNode} from "react";
import {HomeIcon} from "../../shared/icons/HomeIcon";
import {useLocation, useNavigate} from 'react-router-dom'
import {FarmIcon} from "../../shared/icons/FarmIcon";
import {FriendsIcon} from "../../shared/icons/FriendsIcon";
import {EarnIcon} from "../../shared/icons/EarnIcon";
import {AuctionIcon} from "../../shared/icons/AuctionIcon";
import {useTranslation} from "react-i18next";
import {ProfileIcon} from "../../shared/icons/ProfileIcon";

export interface IMenuItem {
    icon: ReactNode
    text: string
    active?: boolean
    disabled?: boolean
    onClick?: () => void
}

export const Menu = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const MenuConfig: IMenuItem[] = [
        {
            icon: <HomeIcon/>,
            text: t("nav.main"),
            onClick: () => {
                navigate("/")
            },
            active: location.pathname === "/",
            disabled: false
        },
        {
            icon: <FarmIcon/>,
            text: t("nav.farm"),
            onClick: () => {
                navigate("/mining")
            },
            active: location.pathname === "/mining",
        },
        {
            icon: <FriendsIcon/>,
            text: t("nav.friends"),
            onClick: () => {
                navigate("/referrals")
            },
            active: location.pathname === "/referrals",
        },
        {
            icon: <AuctionIcon/>,
            text: t("nav.auction"),
            onClick: () => {
                navigate("/settings")
            },
            active: false,
            disabled: true
        },
        {
            icon: <ProfileIcon/>,
            text: t("nav.profile"),
            onClick: () => {
                navigate("/settings")
            },
            active: location.pathname === "/settings",
        },
    ]

    return <MenuWrapper>
        <MenuContainer>
            {MenuConfig.map((value, key) => <MenuItem key={key} {...value}/>)}
        </MenuContainer>
    </MenuWrapper>
}