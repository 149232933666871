import styled from "styled-components";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";

export const RefreshButtonContainer = styled.div<{disabled?: boolean}>`
    width: 49px;
    height: 49px;
    border-radius: 15px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({disabled}) => (disabled ? '#151829' : '#189BFF')};
`

export const NavigationSwitcherContainer = styled(FlexBox)`
    //margin-left: 59px;
`

export const TaskBlockContainer = styled.div`
    background: #1E223B;
    border: 0.7px solid #473F5B;
    border-radius: 15px;
    width: 100%;
`
export const TaskItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 14px;
    
    img {
        min-width: 54px;
        height: 54px;
        border-radius: 10px;
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid #2F334F;
    }
`

export const MiningItemContainer = styled(TaskItem)`
    
    padding: 6px 15px 6px 6px;
    width: 100%;
    box-sizing: border-box;
    background: #1E223B;
    border: 0.7px solid #473F5B;
    border-radius: 15px;
    
    &:not(:last-child) {
        border-bottom: none;
    }
`
export const LevelContainer = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #0000004D;
    
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #FFFFFF;
    padding: 6px 14px;
`