
export const AuctionIcon = () => {
    return <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_1275)">
            <path
                d="M14.3509 13.2913L12.3611 11.2051C12.179 11.0142 11.8885 11.0006 11.6905 11.1727L1.59351 19.9963C1.01548 20.5012 0.66427 21.2439 0.630444 22.0325C0.596618 22.8211 0.882174 23.593 1.41444 24.151L2.00439 24.7696C2.51178 25.3016 3.1863 25.5947 3.90361 25.5947C4.70349 25.5947 5.45564 25.2254 5.968 24.5818L14.3827 13.9954C14.5458 13.7868 14.5329 13.4822 14.3509 13.2913Z"
                fill="white"/>
            <path
                d="M23.5336 10.4034C22.6909 9.51985 21.2245 9.51985 20.3818 10.4034L15.8661 15.138C14.9965 16.0497 14.9965 17.5309 15.8681 18.4446C16.4312 19.035 17.1813 19.3605 17.9782 19.3605C18.7751 19.3605 19.5252 19.035 20.0884 18.4446L23.5356 14.8303C24.6996 13.6098 24.6996 11.6248 23.5336 10.4034Z"
                fill="white"/>
            <path
                d="M16.3167 4.92285C16.1784 5.17215 16.0192 5.40998 15.8193 5.61964L11.3035 10.3543C11.1035 10.564 10.8767 10.7298 10.6389 10.8758L14.6662 15.0983C14.8045 14.849 14.9637 14.6112 15.1636 14.4015L19.6794 9.66689C19.8794 9.45723 20.1102 9.2945 20.3519 9.15368L16.3167 4.92285Z"
                fill="white"/>
            <path
                d="M15.114 1.57534C13.9868 0.393506 12.0209 0.393506 10.8937 1.57534L7.44642 5.18969C6.28341 6.40908 6.28339 8.39412 7.4484 9.61664C7.87023 10.0579 8.42936 10.3009 9.0243 10.3009C9.61924 10.3009 10.1793 10.0579 10.6002 9.61664L15.1159 4.88199C15.9855 3.97032 15.9855 2.48806 15.114 1.57534Z"
                fill="white"/>
            <path
                d="M21.9577 22.5273H16.1128C14.7419 22.5273 13.6256 23.6977 13.6256 25.1351C13.6256 25.423 13.8485 25.6567 14.1231 25.6567H23.9475C24.2221 25.6567 24.4449 25.423 24.4449 25.1351C24.4449 23.6967 23.3297 22.5273 21.9577 22.5273Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1_1275">
                <rect width="23.8771" height="25.0345" fill="white" transform="translate(0.597656 0.655273)"/>
            </clipPath>
        </defs>
    </svg>
}