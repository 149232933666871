import {DefaultPageWrapper} from "../../components/DefaultPageWrapper/DefaultPageWrapper";
import {
    ProfileButton,
    SettingPageContainer,
    SettingPageHeader,
    SettingPageSubHeader,
    SettingPageText,
    SplitLine
} from "./styles";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {LargeAvatar} from "./components/LargeAvatar";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {SettingTabItem} from "./components/SettingTabItem";
import {ImportantIcon} from "../../shared/icons/ImportantIcon";
import {QuestionIcon} from "../../shared/icons/QuestionIcon";
import {TelegramIcon} from "../../shared/icons/TelegramIcon";
import {ArrowFromIcon} from "../../shared/icons/ArrowFromIcon";
import {ArrowToIcon} from "../../shared/icons/ArrowToIcon";
import {CheckBox} from "./components/CheckBox";
import {useNavigate} from "react-router-dom";

export const SettingsPage = () => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate();

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang)
    }

    const gameRulesPage = i18n.language === 'ru' ? 'https://telegra.ph/Pravila-igry-Fluffy-Infinity-11-24' : 'https://telegra.ph/Game-Rules-of-Fluffy-Infinity-11-24'
    const howDistributedPrizesPage = i18n.language === 'ru' ? 'https://telegra.ph/Kak-raspredelyayutsya-prizy-11-24' : 'https://telegra.ph/How-the-awards-are-distributed-11-24'
    const chatPage = i18n.language === 'ru' ? 'https://t.me/Fluffy_Infinity_Chat_RU' : 'https://t.me/Fluffy_Infinity_Chat_ENG'
    const learnMorePage = i18n.language === 'ru' ? 'https://t.me/Fluffy_Infinity' : 'https://t.me/Fluffy_Infinity_ENG'
    const supportPage = 'https://t.me/FluffyInfinitySupport'

    return <DefaultPageWrapper disableNavbar>
        <SettingPageContainer>
            <FlexBox offsetTop={64}>
                <LargeAvatar/>
            </FlexBox>
            <FlexBox offsetTop={10}>
                <TextBlock fontSize={32} lineHeight={32} fontWeight={700}>{t('settings.profile')}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={28} gx={14}>
                <ProfileButton blue onClick={() => navigate("/deposit")}>
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={700}>{t('settings.deposit')}</TextBlock>
                    <ArrowFromIcon/>
                </ProfileButton>
                <ProfileButton onClick={() => navigate("/withdraw")}>
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={700}>{t('settings.withdrawal')}</TextBlock>
                    <ArrowToIcon/>
                </ProfileButton>
            </FlexBox>
            <FlexBox offsetTop={48} width="100%">
                <SplitLine/>
            </FlexBox>
            <FlexBox offsetTop={20} width="calc(100% - 28px)" justify="start" direction="column">
                <TextBlock fontSize={20} lineHeight={20} fontWeight={700}>{t('settings.about_game')}</TextBlock>
                <FlexBox offsetTop={20}>
                    <SettingTabItem text={t('settings.game_rules')} icon={<ImportantIcon/>} action={() => window.open(gameRulesPage, "_blank")}/>
                </FlexBox>
                <FlexBox offsetTop={14}>
                    <SettingTabItem text={t('settings.how_distributed_prizes')} icon={<QuestionIcon width={22} height={22} fill="#FFFFFF"/>} action={() => window.open(howDistributedPrizesPage, "_blank")}/>
                </FlexBox>
                <FlexBox offsetTop={28} width="100%">
                    <SplitLine/>
                </FlexBox>
                <FlexBox offsetTop={20}>
                    <TextBlock fontSize={20} lineHeight={20} fontWeight={700}>{t('settings.community')}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={20}>
                    <SettingTabItem text={t('settings.chat')} icon={<TelegramIcon/>}  action={() => window.open(chatPage, "_self")}/>
                </FlexBox>
                <FlexBox offsetTop={14}>
                    <SettingTabItem text={t('settings.learn_more')} icon={<TelegramIcon/>}  action={() => window.open(learnMorePage, "_self")}/>
                </FlexBox>
                <FlexBox offsetTop={14}>
                    <SettingTabItem text={t('settings.support')} icon={<TelegramIcon/>} action={() => window.open(supportPage, "_self")}/>
                </FlexBox>
                <FlexBox offsetTop={28} width="100%">
                    <SplitLine/>
                </FlexBox>
                <FlexBox offsetTop={20}>
                    <TextBlock fontSize={20} lineHeight={20} fontWeight={700}>{t('settings.language')}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={35}>
                    <CheckBox text={t('settings.english')} value={i18n.language === 'en'} onClick={() => changeLanguage('en')}/>
                </FlexBox>
                <FlexBox offsetTop={14}>
                    <CheckBox text={t('settings.russian')} value={i18n.language === 'ru'} onClick={() => changeLanguage('ru')}/>
                </FlexBox>
            </FlexBox>
        </SettingPageContainer>
    </DefaultPageWrapper>
}