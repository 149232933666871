import {DefaultPageWrapper} from "../../components/DefaultPageWrapper/DefaultPageWrapper";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {EarnedBlock} from "./components/EarnedBlock";
import {InviteBlock} from "./components/InviteBlock";
import {CopyBlock} from "./components/CopyBlock";
import {TopFriendsTable} from "./components/TopFriendsTable";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../shared/hooks/storeHooks";
import {selectorAuth} from "../../shared/slices/authSlice";
import {useGetReferrals} from "../../shared/hooks/api/useGetReferrals";

export const ReferralsPage = () => {

    const { t } = useTranslation()
    const { link } = useAppSelector(selectorAuth)
    const {
        referrals,
        totalTon,
        totalBones,
        isLoading
    } = useGetReferrals()

    return <DefaultPageWrapper>
        <FlexBox justify="center" align="center" direction="column" offsetBottom={80}>
            <TextBlock fontSize={28} lineHeight={18} fontWeight={700}>{t('referrals.invite_friends')}</TextBlock>
            <FlexBox offsetTop={12}>
                <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color="#CFCDCD">{t('referrals.get_income')}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={18} width="calc(100% - 70px)">
                <EarnedBlock coinCount={totalTon} honeyCount={totalBones}/>
            </FlexBox>
            <FlexBox offsetTop={18} width="calc(100% - 70px)" gx={20}>
                <InviteBlock/>
                <CopyBlock text={link}/>
            </FlexBox>
            <FlexBox offsetTop={18} width="calc(100% - 70px)">
                <TopFriendsTable referrals={referrals} isLoading={isLoading}/>
            </FlexBox>
        </FlexBox>
    </DefaultPageWrapper>
}