import React, {useEffect, useState} from 'react';
import './App.css';
import {AppWrapper} from "./components/AppWrapper/AppWrapper";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import CardsPage from "./pages/CardsPage";
import SettingsPage from "./pages/SettingsPage";
import ReferralsPage from "./pages/ReferralsPage";
import {login} from "./shared/actions/login";
import {useAppDispatch, useAppSelector} from "./shared/hooks/storeHooks";
import {loadUser, selectorAuth} from "./shared/slices/authSlice";
import {IS_TEST_ENVIRONMENT} from "./shared/const/isTestEnvironment";
import {WEB_APP_API} from "./shared/const/WebAppApi";
import DepositPage from "./pages/DepositPage";
import WithdrawPage from "./pages/WithdrawPage";
import {createGlobalStyle} from "styled-components";
import {loadReferrals} from "./shared/slices/referralsSlice";
import MiningPage from "./pages/MainingPage";
import {useTranslation} from "react-i18next";
import EmptyScreen from "./components/EmptyScreen";
import {getWebAppParams} from "./shared/actions/getWebAppParams";
import {DesktopBlockScreen} from "./components/DesktopBlockScreen/DesktopBlockScreen";

const BodyGlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    overflow-x: hidden;
  }
`

declare global {
  interface Window {
    Telegram: any;
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrapper><CardsPage /></AppWrapper>,
  },
  {
    path: "/settings",
    element: <AppWrapper isSuperBackground><SettingsPage /></AppWrapper>,
  },
  {
    path: "/referrals",
    element: <AppWrapper isSuperBackground><ReferralsPage /></AppWrapper>,
  },
  {
    path: "/deposit",
    element: <AppWrapper isPaymentBackground><DepositPage /></AppWrapper>,
  },
  {
    path: "/withdraw",
    element: <AppWrapper isPaymentBackground><WithdrawPage /></AppWrapper>,
  },
  {
    path: "/mining",
    element: <AppWrapper isSuperBackground><MiningPage /></AppWrapper>,
  },
]);

function isDesktop() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /windows|macintosh|linux/i.test(userAgent) && !/mobile|android|iphone|ipad/i.test(userAgent);
}

function App() {

  const dispatch = useAppDispatch()
  const authData = useAppSelector(selectorAuth)
  const { i18n } = useTranslation()
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {

    // Инициализация
    WEB_APP_API.ready();

    // Получение данных о пользователе
    // const user = WEB_APP_API.initDataUnsafe?.user;
    dispatch(loadUser({
      onBlock: () => {
        setIsBlocked(true)
      }
    }))

    WEB_APP_API.setHeaderColor("#0B0914");
    WEB_APP_API.expand()

    const languageCode = WEB_APP_API.initDataUnsafe?.user?.language_code

    if (['ru', 'en'].includes(localStorage.getItem("language") || '')) i18n.changeLanguage(localStorage.getItem("language") || '');
    else if (['ru', 'en'].includes(languageCode)) i18n.changeLanguage(languageCode)

    // Настройка основной кнопки
    // tg.MainButton.setText('Нажми меня');
    // tg.MainButton.show();

    // alert(isDesktop() ? "Пользователь зашел с компьютера" : "Пользователь зашел с телефона")
      }, []);

/*  if (isDesktop()) return (
      <>
        <BodyGlobalStyle/>
        <DesktopBlockScreen/>
      </>
  );*/

  if (isBlocked) return (
      <>
        <BodyGlobalStyle/>
        <EmptyScreen/>
      </>
  );

  if (!IS_TEST_ENVIRONMENT && authData.error && !authData.isLoading) return <div>ПРИЛОЖЕНИЕ ОТКРЫТО ВНЕ ТЕЛЕГРАММА</div>

  return (
      <>
        <BodyGlobalStyle/>
        <RouterProvider router={router} />
      </>
  );
}

export default App;
