import {FC} from "react";

export const RefreshIcon: FC<{ width?: number, height?: number, fill?: string}> = ({width, height, fill}) => {
    return <svg width={width || 26} height={height || 26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8.40741C22.1705 3.69778 17.6612 1 12.7997 1C6.65011 1 1.59537 6.26222 1 13" stroke={fill || '#FFFFFF'}
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M19.0713 8.78947H24.289C24.4772 8.78947 24.6577 8.71628 24.7908 8.58599C24.9239 8.4557 24.9987 8.27899 24.9987 8.09474V3M2 18.5673C3.82949 22.6572 8.3388 25 13.2003 25C19.3499 25 24.4046 20.4302 25 14.5789"
            stroke={fill || '#FFFFFF'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M7 18H1.71957C1.62514 17.9998 1.5316 18.0183 1.44431 18.0544C1.35701 18.0905 1.27768 18.1435 1.21084 18.2104C1.144 18.2773 1.09098 18.3567 1.0548 18.4442C1.01862 18.5316 1 18.6253 1 18.72V24"
            stroke={fill || '#FFFFFF'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}