import React, {FC} from "react";
import {RightItemWrapper} from "../styles";
import {HoneyIcon} from "../../../shared/icons/HoneyIcon";


export const NavbarRightItem: FC<{count: number}> = ({ count }) => {

    return <RightItemWrapper>
        <span>{count}</span>
        <HoneyIcon height={24} width={24}/>
    </RightItemWrapper>
}