import styled from "styled-components";

enum FontFamilyList {

}

export interface TextProps {
    fontSize: number;
    lineHeight: number;
    fontWeight?: number;
    color?: string;
    fontFamily?: FontFamilyList;
}

export const TextBlock = styled.div<TextProps>`
    font-size: ${({fontSize}) => `${fontSize}px`};
    line-height: ${({lineHeight}) => `${lineHeight}px`};
    font-weight: ${({fontWeight}) => fontWeight || 400};
    color: ${({color}) => color || '#FFFFFF'};
`