
export const ImportantIcon = () => {
    return <svg width="22" height="22" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 26C18.0751 26 23 21.0751 23 15C23 8.92487 18.0751 4 12 4C5.92487 4 1 8.92487 1 15C1 21.0751 5.92487 26 12 26Z"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M10.9766 20.375L11.1641 12.3516L12.7734 12.3516L12.9687 20.375L10.9766 20.375ZM13.0234 9.89062C13.0234 10.1771 12.9297 10.4167 12.7422 10.6094C12.5495 10.8073 12.2839 10.9062 11.9453 10.9062C11.612 10.9062 11.349 10.8073 11.1563 10.6094C10.9635 10.4167 10.8672 10.1771 10.8672 9.89062C10.8672 9.61458 10.9635 9.3776 11.1563 9.17969C11.349 8.98698 11.612 8.89062 11.9453 8.89062C12.2839 8.89062 12.5495 8.98698 12.7422 9.17969C12.9297 9.3776 13.0234 9.61458 13.0234 9.89062Z"
            fill="white"/>
    </svg>
}