import {useAppDispatch, useAppSelector} from "../storeHooks";
import {useEffect, useMemo} from "react";
import {selectorAuth} from "../../slices/authSlice";
import {Factory, loadFactory, selectorFarm, UserFactory} from "../../slices/farmSlice";
import {IMAGE_ENDPOINT} from "../../const/endpoint";

export const useGetFarm = () => {

    const data = useAppSelector(selectorFarm)
    const auth = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (data.isLoading || data.isReady || !auth.token || data.error) return
        dispatch(loadFactory({}))

    }, [data.isLoading, data.isReady, auth.token, data.error])

    const factories = data.allFactories.map(factory => {

        const index = data.userFactories.findIndex(userFactory => userFactory.factory_id === factory.id)
        if (index === -1) return {...factory, user_id: 0, factory_id: factory.id, level_id: 0, profit_per_hour: 0, next_level_cost: factory.cost, image_url: IMAGE_ENDPOINT + (factory?.image_url || '')}
        return {...factory, ...data.userFactories[index], image_url: IMAGE_ENDPOINT + (factory?.image_url || '')}
    }) as unknown as (Factory & UserFactory)[]

    const categories = data.categories

    return { factories, categories }
}