
export const TimeIcon = () => {
    return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_925)">
            <path
                d="M6.875 4.31383C6.71734 4.31383 6.56242 4.23224 6.47809 4.08466L5.56142 2.49699C5.43492 2.27699 5.51009 1.99741 5.72917 1.87091C5.94734 1.74441 6.22875 1.81774 6.35525 2.03866L7.27192 3.62633C7.39842 3.84541 7.32325 4.12499 7.10417 4.25241C7.03175 4.29366 6.95292 4.31383 6.875 4.31383Z"
                fill="white"/>
            <path
                d="M16.0417 20.1905C15.884 20.1905 15.7291 20.1089 15.6448 19.9613L14.7281 18.3737C14.6007 18.1546 14.6758 17.8741 14.8958 17.7476C15.114 17.6202 15.3954 17.6953 15.5219 17.9153L16.4386 19.503C16.566 19.7221 16.4908 20.0026 16.2708 20.1291C16.1984 20.1712 16.1196 20.1905 16.0417 20.1905Z"
                fill="white"/>
            <path
                d="M3.8555 7.3335C3.77758 7.3335 3.69875 7.31425 3.62633 7.27209L2.03867 6.35542C1.81867 6.22892 1.7435 5.94842 1.87092 5.72934C1.99742 5.51025 2.27792 5.43692 2.497 5.56159L4.08467 6.47825C4.30375 6.60475 4.37892 6.88525 4.25242 7.10434C4.16808 7.251 4.01317 7.3335 3.8555 7.3335Z"
                fill="white"/>
            <path
                d="M19.7322 16.5C19.6542 16.5 19.5754 16.4808 19.503 16.4386L17.9153 15.5219C17.6963 15.3954 17.6211 15.1149 17.7476 14.8958C17.8741 14.6768 18.1546 14.6034 18.3737 14.7281L19.9613 15.6448C20.1804 15.7713 20.2556 16.0518 20.1291 16.2708C20.0447 16.4175 19.8898 16.5 19.7322 16.5Z"
                fill="white"/>
            <path
                d="M2.26783 16.5001C2.11017 16.5001 1.95525 16.4176 1.87092 16.271C1.7435 16.0519 1.81867 15.7714 2.03867 15.6449L3.62633 14.7282C3.8445 14.6026 4.12592 14.676 4.25242 14.896C4.37983 15.115 4.30467 15.3955 4.08467 15.522L2.497 16.4387C2.42458 16.4809 2.34575 16.5001 2.26783 16.5001Z"
                fill="white"/>
            <path
                d="M5.95834 20.1907C5.88042 20.1907 5.80159 20.1714 5.72917 20.1292C5.51009 20.0027 5.43492 19.7222 5.56142 19.5032L6.47809 17.9155C6.60367 17.6955 6.88417 17.6222 7.10417 17.7477C7.32325 17.8742 7.39842 18.1547 7.27192 18.3738L6.35525 19.9615C6.27092 20.1091 6.116 20.1907 5.95834 20.1907Z"
                fill="white"/>
            <path
                d="M2.75 11.4582H0.916669C0.663669 11.4582 0.458336 11.2528 0.458336 10.9998C0.458336 10.7468 0.663669 10.5415 0.916669 10.5415H2.75C3.003 10.5415 3.20834 10.7468 3.20834 10.9998C3.20834 11.2528 3.003 11.4582 2.75 11.4582Z"
                fill="white"/>
            <path
                d="M11 21.5415C10.747 21.5415 10.5417 21.3362 10.5417 21.0832V19.2498C10.5417 18.9968 10.747 18.7915 11 18.7915C11.253 18.7915 11.4583 18.9968 11.4583 19.2498V21.0832C11.4583 21.3362 11.253 21.5415 11 21.5415Z"
                fill="white"/>
            <path
                d="M20.3427 6.13541L18.3737 7.27208C18.3013 7.31425 18.2224 7.3335 18.1445 7.3335C17.9868 7.3335 17.8319 7.251 17.7476 7.10433C17.6202 6.88525 17.6953 6.60475 17.9153 6.47825L19.8807 5.34341C19.0529 4.04816 17.952 2.94725 16.6558 2.1195L15.521 4.08483C15.4367 4.23241 15.2818 4.314 15.1241 4.314C15.0462 4.314 14.9673 4.29475 14.8949 4.25258C14.6758 4.12608 14.6007 3.84558 14.7272 3.6265L15.8638 1.65841C14.4082 0.895746 12.7554 0.458496 11 0.458496C10.747 0.458496 10.5417 0.663829 10.5417 0.916829V11.0002C10.5417 11.2532 10.747 11.4585 11 11.4585H21.0833C21.3363 11.4585 21.5417 11.2532 21.5417 11.0002C21.5417 9.24566 21.1044 7.59291 20.3427 6.13541Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1_925">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>


}