import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {useTranslation} from "react-i18next";
import {FC} from "react";
import {TaskBlock} from "./TaskBlock";
import {Task} from "../../../../shared/slices/tasksSlice";

export const TaskList: FC<{tasks: Task[]}> = ({tasks}) => {

    const { t } = useTranslation()

    const dailyTasks = tasks.filter(item => item.frequency === 'daily')
    const onceTasks = tasks.filter(item => item.frequency === 'once')

    return <FlexBox direction="column" width="100%" gy={40}>
        <TaskBlock items={dailyTasks} title={t('tasks.daily')}/>
        {/*<TaskBlock items={items} title={t('tasks.weekly')}/>*/}
        <TaskBlock items={onceTasks} title={t('tasks.once')}/>
    </FlexBox>
}