import {DefaultPageWrapper} from "../../components/DefaultPageWrapper/DefaultPageWrapper";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {QuestionBlock} from "./components/QuestionBlock";
import {CardsInfoBlock} from "./components/CardsInfoBlock";
import {CardsSelectorBlock} from "./components/CardSelector/CardsSelectorBlock";
import {useGetGameInfo} from "../../shared/hooks/api/useGetGameInfo";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";

export const CardsPage = () => {

    const { currentBank, hasUserBet, bettedCardId, game } = useGetGameInfo()
    const { i18n } = useTranslation()
    const description = useMemo(() => i18n.language === 'ru' ? game?.round?.description_ru : game?.round?.description_en, [i18n.language, game])

    return <DefaultPageWrapper>
        <FlexBox justify="flex-start" align="center" direction="column" style={{flexGrow: 1}}>
            <QuestionBlock description={description}/>
            <FlexBox width='100%'>
                <CardsInfoBlock bankRound={currentBank} untilEnd={game.round.end_date}/>
            </FlexBox>
            <FlexBox width='100%' style={{flexGrow: 1}}>
                <CardsSelectorBlock hasUserBet={hasUserBet} gameInfo={game} bettedCardId={bettedCardId}/>
            </FlexBox>
        </FlexBox>
    </DefaultPageWrapper>
}