import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";

export interface Factory {
    id: number,
    name_ru: string,
    name_en: string,
    image_url: string,
    cost: number,
    max_level: number,
    profit: number
}

export interface FactoryCategory {
    id: number,
    title_ru: string,
    title_en: string,
    factory_ids: number[]
}

export interface UserFactory {
    user_id: number,
    factory_id: number,
    level_id: number,
    profit_per_hour: number,
    next_level_cost: number,
    isLoading?: boolean
    error?: any
}

interface farmInitial {
    isLoading: boolean
    isReady: boolean
    availableRewards: {
        value: number
        isLoading: boolean
        error: any
    }
    error: any
    categories: FactoryCategory[]
    allFactories: Factory[]
    userFactories: UserFactory[]
}

const initialState: farmInitial = {
    isLoading: false,
    isReady: false,
    error: null,
    availableRewards: {
        value: 0,
        isLoading: false,
        error: null
    },
    categories: [],
    allFactories: [],
    userFactories: []
}

interface LoadFactoryResponse {
    categories: FactoryCategory[]
    allFactories: Factory[]
    userFactories: UserFactory[]
    availableRewards: number
}
interface UpgradeFactoryResponse {
    profit_per_hour: number
    next_level_cost: number
}


export const loadFactory = createAsyncThunk<LoadFactoryResponse, {}>(
    'factory/Load',
    async ({}, thunkAPI) => {
        try {
            const requests = [
                await axios.get(API_ENDPOINT + 'factory-api/all-factories', {}),
                await axios.get(API_ENDPOINT + 'factory-api/user-factories', {}),
                await axios.get(API_ENDPOINT + 'factory-api/user-factories/available-rewards', {}),
            ];

            const responses = await Promise.all(requests);

            // Получение данных из ответов
            const [allFactories, userFactories, availableRewards] = responses.map(response => response.data);

            const allFactoriesData = allFactories.flatMap((item: any) => item?.factories || []);
            const categories = allFactories.map((item: any) => ({
                id: item.id,
                title_ru: item.title_ru,
                title_en: item.title_en,
                factory_ids: item.factories.map((factory: any) => factory.id)
            }));


            return {
                categories: categories || [],
                allFactories: allFactoriesData || [],
                userFactories: userFactories || [],
                availableRewards: availableRewards || 0
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load factory')
        }
    }
)

export const buyFactory = createAsyncThunk<UserFactory, { factoryId: number }>(
    'factory/Buy',
    async ({ factoryId }, thunkAPI) => {
        try {

            const response = await axios.post(API_ENDPOINT + 'factory-api/buy-factory/' + factoryId, {}) as any

            if (!response?.data?.factory_id) return thunkAPI.rejectWithValue('Failed to buy factory')

            return {
                ...response?.data
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to buy factory')
        }
    }
)
export const upgradeFactory = createAsyncThunk<UpgradeFactoryResponse, { factoryId: number }>(
    'factory/Upgrade',
    async ({ factoryId }, thunkAPI) => {
        try {
            const response = await axios.post(API_ENDPOINT + 'factory-api/upgrade-factory/' + factoryId, {}) as any

            if (!response?.data?.factory_id) return thunkAPI.rejectWithValue('Failed to buy factory')

            return {
                profit_per_hour: response?.data?.profit_per_hour || 0,
                next_level_cost: response?.data?.next_level_cost || 0,
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to upgrade factory')
        }
    }
)
export const collectRewards = createAsyncThunk<{ rewardsCount: number  }, {}>(
    'rewards/Collect',
    async ({}, thunkAPI) => {
        try {
            const response = await axios.post(API_ENDPOINT + 'factory-api/user-factories/collect-rewards', {}) as any

/*            const totalCollectedRewards = (response?.data || []).reduce((accumulator: any, current: any) => {
                return accumulator + current?.collected_rewards;
            }, 0);*/

            return {
                rewardsCount: response?.data?.sum || 0
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to collect rewards')
        }
    }
)

const farmSlice = createSlice({
    name: 'farm',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadFactory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isReady = true
                state.categories = action.payload.categories
                state.allFactories = action.payload.allFactories
                state.userFactories = action.payload.userFactories
                state.availableRewards.value = action.payload.availableRewards
                state.availableRewards.isLoading = false
            })
            .addCase(loadFactory.pending, (state) => {
                state.isLoading = true
                state.availableRewards.isLoading = true
                state.error = ''
            })
            .addCase(loadFactory.rejected, (state, action) => {
                state.isLoading = false
                state.availableRewards.isLoading = false
                state.error = action.payload
            })
            .addCase(upgradeFactory.fulfilled, (state, action) => {

                const index = state.userFactories.findIndex(factory => factory.factory_id === action.meta.arg.factoryId)
                if (index === -1) return;

                state.userFactories[index] = {
                    ...state.userFactories[index],
                    isLoading: false,
                    profit_per_hour: action.payload.profit_per_hour,
                    next_level_cost: action.payload.next_level_cost,
                    level_id: state.userFactories[index].level_id + 1
                }
            })
            .addCase(upgradeFactory.pending, (state, action) => {
                const index = state.userFactories.findIndex(factory => factory.factory_id === action.meta.arg.factoryId)
                if (index === -1) return;

                state.userFactories[index] = {
                    ...state.userFactories[index],
                    isLoading: true
                }
            })
            .addCase(upgradeFactory.rejected, (state, action) => {

                const index = state.userFactories.findIndex(factory => factory.factory_id === action.meta.arg.factoryId)
                if (index === -1) return;

                state.userFactories[index] = {
                    ...state.userFactories[index],
                    isLoading: false,
                    error: action.payload
                }
            })
            .addCase(buyFactory.fulfilled, (state, action) => {

                const index = state.userFactories.findIndex(factory => factory.factory_id === action.meta.arg.factoryId)
                if (index === -1) return;

                state.userFactories[index] = {
                    ...state.userFactories[index],
                    ...action.payload,
                    isLoading: false,
                }
            })
            .addCase(buyFactory.pending, (state, action) => {
                state.userFactories.push({
                    isLoading: true,
                    factory_id: action.meta.arg.factoryId,
                    level_id: 0,
                    user_id: 0,
                    profit_per_hour: 0,
                    next_level_cost: 0
                })
            })
            .addCase(buyFactory.rejected, (state, action) => {

                const index = state.userFactories.findIndex(factory => factory.factory_id === action.meta.arg.factoryId)
                if (index === -1) return;

                state.userFactories[index] = {
                    ...state.userFactories[index],
                    isLoading: false,
                    error: action.payload
                }
            })
            .addCase(collectRewards.fulfilled, (state, action) => {
                state.availableRewards.value = 0
                state.availableRewards.isLoading = false
            })
            .addCase(collectRewards.pending, (state, action) => {
                state.availableRewards.isLoading = true
            })
            .addCase(collectRewards.rejected, (state, action) => {
                state.availableRewards.value = 0
                state.availableRewards.isLoading = false
                state.availableRewards.error = action.payload
            })
    }
})

export const {} = farmSlice.actions
export const selectorFarm = (state: any): farmInitial => state.farm
export default farmSlice