import styled from "styled-components";

export const RequestModalWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 364px;
    padding: 12px 15px;
    box-sizing: border-box;
    flex-direction: column;
    background: linear-gradient(180deg, #161222 0%, #2C2545 100%);
    border-radius: 25px;
`