import {
    NotificationItemContainer,
    NotificationItemWallet,
    NotificationItemWalletIconWrapper,
    NotificationScreenContainer
} from "./styles";
import {useGetNotification} from "../../shared/hooks/api/useGetNotification";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {ProfileButton} from "../../pages/SettingsPage/styles";
import {TonIcon} from "../../pages/DepositPage/styles";
import {HoneyIcon} from "../../shared/icons/HoneyIcon";
import {useAppDispatch} from "../../shared/hooks/storeHooks";
import {confirmNotification} from "../../shared/slices/notificationSlice";
import {createGlobalStyle} from "styled-components";

const BodyGlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`

export const NotificationItem = () => {

    const { notification } = useGetNotification()
    const { t }  = useTranslation()
    const dispatch = useAppDispatch()

    const onClick = () => {
        dispatch(confirmNotification({notificationId: notification?.id || 0}))
    }

    if (!notification) return null

    return <NotificationScreenContainer onClick={onClick}>
        <BodyGlobalStyle/>
        <NotificationItemContainer onClick={(e) => e.stopPropagation()}>
        <FlexBox offsetTop={16} direction="column">
            <TextBlock fontSize={24} lineHeight={24} fontWeight={500} color={'#FFFFFF'} style={{textAlign: 'center'}}>{t('notification.round_results_1')}</TextBlock>
            <TextBlock fontSize={24} lineHeight={24} fontWeight={500} color={'#FFFFFF'} style={{textAlign: 'center'}}>{t('notification.round_results_2')}</TextBlock>
        </FlexBox>
        <FlexBox justify="center" offsetTop={16}>
            <TextBlock fontSize={15} lineHeight={16} fontWeight={400} color={'#FFFFFF8C'}>{t('notification.all', {count: notification.total_bank})}</TextBlock>
        </FlexBox>
        <FlexBox justify="space-between" width="calc(100vw - 72px)" gx={16} offsetTop={32}>
            <NotificationItemWallet>
                <TextBlock fontSize={20} lineHeight={20} fontWeight={600} color={'#FFFFFF'} style={{textAlign: 'center'}}>{notification.tones_win > 0 ? '+' : ''}{Math.round(notification.tones_win * 100) / 100}</TextBlock>
                <TextBlock fontSize={20} lineHeight={20} fontWeight={500} color={'#FFFFFFBF'}>TON</TextBlock>
                <NotificationItemWalletIconWrapper>
                    <TonIcon style={{position: 'initial', width: '36px', height: '36px'}}/>
                </NotificationItemWalletIconWrapper>
            </NotificationItemWallet>
            <NotificationItemWallet>
                <TextBlock fontSize={20} lineHeight={20} fontWeight={600} color={'#FFFFFF'} style={{textAlign: 'center'}}>{notification.bones_win > 0 ? '+' : ''}{notification.bones_win}</TextBlock>
                <TextBlock fontSize={20} lineHeight={20} fontWeight={500} color={'#FFFFFFBF'}>HONEY</TextBlock>
                <NotificationItemWalletIconWrapper>
                    <HoneyIcon width={36} height={36}/>
                </NotificationItemWalletIconWrapper>
            </NotificationItemWallet>
        </FlexBox>
        <FlexBox justify="center" offsetTop={28}>
            <ProfileButton height="58px" blue width={'calc(100vw - 72px)'} onClick={onClick}>
                <TextBlock fontSize={20} lineHeight={20} fontWeight={700} color={'#FFFFFF'}>{t('notification.close')}</TextBlock>
            </ProfileButton>
        </FlexBox>
    </NotificationItemContainer>
    </NotificationScreenContainer>
}