import {DefaultPageWrapper} from "../../components/DefaultPageWrapper/DefaultPageWrapper";
import {WithdrawContainer} from "./styles";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {ProfileButton, SplitLine} from "../SettingsPage/styles";
import {RequestModal} from "../../shared/modals/RequestModal/RequestModal";
import {useEffect, useMemo, useState} from "react";
import {RequestModalSend} from "../../shared/modals/RequestModal/RequestModalSend";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {InputItem} from "../DepositPage/components/InputItem";
import {createDeposit} from "../../shared/actions/api/createDeposit";
import {createCashout} from "../../shared/actions/api/createCashout";
import {WEB_APP_API} from "../../shared/const/WebAppApi";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/storeHooks";
import {selectorAuth, setTon} from "../../shared/slices/authSlice";

const COMISSION = 0.2

export const WithdrawPage = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [address, setAddress] = useState('')
    const [sum, setSum] = useState('')
    const { notTokens } = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()
    const sumNumber = parseFloat(sum) || 0

    const balanceAfterTransfer = notTokens - COMISSION - sumNumber
    const receiveSum = sumNumber

    const [isLoading, setIsLoading] = useState(false)
    const isButtonDisabled = useMemo(() => sumNumber <= 0 || !address || isLoading || balanceAfterTransfer < 0, [sumNumber, address, isLoading, balanceAfterTransfer])

    useEffect(() => {
        const handleBackButton = () => {
            navigate(-1)
        }

        WEB_APP_API.BackButton.show();
        WEB_APP_API.BackButton.offClick(handleBackButton);
        WEB_APP_API.BackButton.onClick(handleBackButton);

        return () => {
            WEB_APP_API.BackButton.offClick(handleBackButton);
            WEB_APP_API.BackButton.hide();
        }
    }, []);

    const onSubmit = () => {
        if (isButtonDisabled) return;
        setIsLoading(true)
        createCashout(address, sumNumber).then((r) => {
            setIsLoading(false)
            setIsModalOpen(true)
            dispatch(setTon(balanceAfterTransfer))
        })
            .catch((err) => toast(t('withdraw.error')))
    }
    const onCloseModal = () => {
        setIsModalOpen(false)
        navigate(-1)
    }

    return <DefaultPageWrapper disableNavbar disableMenu>
        <FlexBox direction="column" width="100%" align="center" justify="space-between" style={{flexGrow: 1}}>
            <FlexBox direction="column" width="100%" align="center">
                <FlexBox offsetTop={24} justify="flex-start" width="calc(100% - 76px)">
                    <TextBlock fontSize={28} lineHeight={28} fontWeight={700} style={{textAlign: "left"}}>{t('withdraw.title')}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={16}  width="calc(100% - 76px)">
                    <TextBlock fontSize={15} lineHeight={18} fontWeight={700} style={{textAlign: "left"}}>{t('withdraw.description')}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={30} direction="column" width="calc(100% - 76px)">
                    <InputItem
                        onChange={(e) => {
                            if (/^\d*\.?\d{0,2}$/.test(e)) {
                                setSum(e);
                            }
                        }} value={String(sum)} text={t('withdraw.sum')} withIcon/>
                </FlexBox>
                <FlexBox offsetTop={30} direction="column" width="calc(100% - 76px)">
                    <InputItem onChange={setAddress} value={address} text={t('withdraw.input_address')}/>
                </FlexBox>
                <FlexBox offsetTop={28} width="calc(100% - 76px)">
                    <SplitLine/>
                </FlexBox>
                <FlexBox style={{marginLeft: '10px'}} gy={4} offsetTop={10} direction="column" width="calc(100% - 66px)">
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={400} color="#FFFFFFCC" style={{textAlign: "left"}}>{t('withdraw.transfer_commission')}</TextBlock>
                    <TextBlock fontSize={19} lineHeight={19} fontWeight={400} style={{textAlign: "left"}}>{`${COMISSION} TON`}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={15} direction="column" width="calc(100% - 76px)">
                    <SplitLine/>
                </FlexBox>
                <FlexBox style={{marginLeft: '10px'}} gy={4} offsetTop={10} direction="column" width="calc(100% - 66px)">
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={400} color="#FFFFFFCC" style={{textAlign: "left"}}>{t('withdraw.balance_after')}</TextBlock>
                    <TextBlock fontSize={19} lineHeight={19} fontWeight={400} style={{textAlign: "left"}}>{`${balanceAfterTransfer < 0 ? '--' : balanceAfterTransfer} TON`}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={24} direction="column" width="100%">
                    <SplitLine/>
                </FlexBox>
                <FlexBox style={{marginLeft: '10px'}} gy={4} offsetTop={10} direction="column" width="calc(100% - 66px)">
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={400} color="#FFFFFFCC" style={{textAlign: "left"}}>{t('withdraw.get')}</TextBlock>
                    <TextBlock fontSize={32} lineHeight={32} fontWeight={500} style={{textAlign: "left"}}>{`${receiveSum < 0 ? '--' : receiveSum} TON`}</TextBlock>
                </FlexBox>
            </FlexBox>
            <FlexBox width="calc(100% - 76px)" offsetBottom={24} offsetTop={16}>
                <ProfileButton disabled={isButtonDisabled} blue width="100%" onClick={onSubmit}>
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={700}>{t('withdraw.title')}</TextBlock>
                </ProfileButton>
            </FlexBox>
        </FlexBox>
        <RequestModalSend isOpen={isModalOpen} closeModal={onCloseModal}/>
    </DefaultPageWrapper>
}