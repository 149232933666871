import {FC} from "react";
import {TextBlock} from "../../../../shared/components/Text/TextBlock";
import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {TaskBlockContainer, TaskItem} from "../../styles";
import {ProfileButton} from "../../../SettingsPage/styles";
import {HoneyIcon} from "../../../../shared/icons/HoneyIcon";
import {DoneIcon} from "../../../../shared/icons/DoneIcon";
import {collectTask, Task} from "../../../../shared/slices/tasksSlice";
import {useTranslation} from "react-i18next";
import {setHoney} from "../../../../shared/slices/authSlice";
import {buyFactory, upgradeFactory} from "../../../../shared/slices/farmSlice";
import {useAppDispatch} from "../../../../shared/hooks/storeHooks";

export const TaskBlock: FC<{title: string, items: Task[]}>  = ({title, items}) => {

    const { i18n } = useTranslation();

    const dispatch = useAppDispatch()

    const onCollect = (ruleId: number) => {

        dispatch(collectTask({ruleId}))
    }

    if (items?.length === 0) return null;

    return <FlexBox direction="column" width="100%" gy={6} align="flex-start">
        <TextBlock fontSize={20} lineHeight={20} fontWeight={700} style={{marginLeft: '14px'}}>{title}</TextBlock>
        <TaskBlockContainer>
            {items.map((item, index) => {

                const isDisabled = item.status === 'in_progress'

               return <TaskItem key={index}>
                <TextBlock fontSize={16} lineHeight={16} fontWeight={700} color="#FFFFFF" style={{textAlign: "left"}}>{i18n.language === 'ru' ? item?.description_ru : item?.description_en}</TextBlock>
                {item.status === 'collected' ? <FlexBox style={{width: '105px', minWidth: '105px', height: '50px'}} justify="center" align="center">
                    <DoneIcon/>
                </FlexBox> : <ProfileButton disabled={isDisabled} blue width={'105px'} onClick={() => !isDisabled && onCollect(item?.rule_id)}>
                    <HoneyIcon width={22} height={22}/><TextBlock fontSize={16} lineHeight={16} fontWeight={500} color="#FFFFFF">{item?.bonus_amount}</TextBlock>
                </ProfileButton>}
            </TaskItem>
            })}
        </TaskBlockContainer>
    </FlexBox>
}