
export const BankIcon = () => {
    return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_926)">
            <path
                d="M0.449155 7.33319H21.5325C21.5398 7.33411 21.5472 7.33319 21.5508 7.33319C21.8038 7.33319 22.0092 7.12786 22.0092 6.87486C22.0092 6.67869 21.8854 6.51095 21.7122 6.44587L11.6958 0.0713624C11.5509 -0.0203043 11.3676 -0.0239799 11.2182 0.0621867L0.21817 6.47885C0.0394203 6.58335 -0.0476731 6.79512 0.0073269 6.99495C0.0604936 7.19479 0.242905 7.33319 0.449155 7.33319Z"
                fill="white"/>
            <path
                d="M21.5325 18.333H0.449155C0.196155 18.333 -0.00917816 18.5383 -0.00917816 18.7913V21.5413C-0.00917816 21.7943 0.196155 21.9997 0.449155 21.9997H21.5325C21.7855 21.9997 21.9908 21.7943 21.9908 21.5413V18.7913C21.9908 18.5383 21.7864 18.333 21.5325 18.333Z"
                fill="white"/>
            <path
                d="M6.86582 9.16667C7.11882 9.16667 7.32416 8.96133 7.32416 8.70833C7.32416 8.45533 7.11974 8.25 6.86582 8.25H5.94916H3.19916H2.28249C2.02949 8.25 1.82416 8.45533 1.82416 8.70833C1.82416 8.96133 2.02949 9.16667 2.28249 9.16667H2.74082V16.5H2.28249C2.02949 16.5 1.82416 16.7053 1.82416 16.9583C1.82416 17.2113 2.02949 17.4167 2.28249 17.4167H3.19916H5.94916H6.86582C7.11882 17.4167 7.32416 17.2113 7.32416 16.9583C7.32416 16.7053 7.11974 16.5 6.86582 16.5H6.40749V9.16667H6.86582Z"
                fill="white"/>
            <path
                d="M13.2825 9.16667C13.5355 9.16667 13.7408 8.96133 13.7408 8.70833C13.7408 8.45533 13.5364 8.25 13.2825 8.25H12.3658H9.61582H8.69916C8.44616 8.25 8.24082 8.45533 8.24082 8.70833C8.24082 8.96133 8.44616 9.16667 8.69916 9.16667H9.15749V16.5H8.69916C8.44616 16.5 8.24082 16.7053 8.24082 16.9583C8.24082 17.2113 8.44616 17.4167 8.69916 17.4167H9.61582H12.3658H13.2825C13.5355 17.4167 13.7408 17.2113 13.7408 16.9583C13.7408 16.7053 13.5355 16.5 13.2825 16.5H12.8242V9.16667H13.2825Z"
                fill="white"/>
            <path
                d="M19.6992 9.16667C19.9522 9.16667 20.1575 8.96133 20.1575 8.70833C20.1575 8.45533 19.9531 8.25 19.6992 8.25H18.7825H16.0325H15.1158C14.8628 8.25 14.6575 8.45533 14.6575 8.70833C14.6575 8.96133 14.8628 9.16667 15.1158 9.16667H15.5742V16.5H15.1158C14.8628 16.5 14.6575 16.7053 14.6575 16.9583C14.6575 17.2113 14.8628 17.4167 15.1158 17.4167H16.0325H18.7825H19.6992C19.9522 17.4167 20.1575 17.2113 20.1575 16.9583C20.1575 16.7053 19.9522 16.5 19.6992 16.5H19.2408V9.16667H19.6992Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1_926">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>


}