import styled from "styled-components";

export const NavbarWrapper = styled.div`
    //background: linear-gradient(360deg, #2D2647 0%, #000000 100%);
    //width: 100vw;
    //height: 120px;
    //
    //border: 1px solid;
    //
    //border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.2) -104.17%, rgba(0, 0, 0, 0.2) 65.83%);

    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 120px;
    top: -64px;
    margin-bottom: -64px;
    
    svg {
        z-index: 5;
        width: 100%;
        height: 122px;
        
    }

    //background: linear-gradient(90deg, #606728 0%, #1D4336 100%);
    
`;

export const HeaderItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #4caf50;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    margin: 0 10px;
    
    &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }
    &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }
`;

export const Avatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const NavbarItemWrapper = styled.div`

    width: 33.5vw;
    height: 44px;
    
    display: flex;
    align-items: center;
    gap: 2px;

    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    color: #FFFFFF;
    
    font-family: "Roboto", sans-serif;
    top: 64px;
    position: absolute;
    
    span {
        margin-top: 2px;
    }
`

export const RightItemWrapper = styled(NavbarItemWrapper)`
    background: linear-gradient(90deg, #1D4336 0%, #606728 100%);
    
    justify-content: flex-end;
    padding: 0 8px 0 0;
    gap: 4px;
    
    right: 0;
`

export const LeftItemWrapper = styled(NavbarItemWrapper)`
    background: linear-gradient(90deg, #606728 0%, #1D4336 100%);
    
    justify-content: flex-start;
    padding: 0 0 0 8px;
    
    left: 0;
;
`

export const NavbarAccountWrapper = styled.div`
    
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 33.3vw;

    position: absolute;
    bottom: 7px;
    left: 33.3vw;
    z-index: 10;

    span {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 15px;
        font-weight: 700;
        color: #FFFFFF;
    }
;
`
export const AvatarWrapper = styled.div`
    font-family: "Roboto", sans-serif;
    border: 1px #13626C solid;
    border-radius: 100%;
    width: 43px;
    height: 43px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 41px;
        height: 41px;
        border-radius: 100%;
    }
;
`
