import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {useTranslation} from "react-i18next";
import {FC} from "react";
import {TextBlock} from "../../../../shared/components/Text/TextBlock";
import {MiningItem} from "./MiningItem";
import {useGetFarm} from "../../../../shared/hooks/api/useGetFarm";
import {Factory, FactoryCategory, UserFactory} from "../../../../shared/slices/farmSlice";

export const MiningList: FC<{factories: (Factory & UserFactory)[], factoryCategories: FactoryCategory[]}> = ({factories, factoryCategories}) => {

    const { i18n } = useTranslation()

    return <FlexBox direction="column" width="100%" gy={40}>
        {factoryCategories.map(category => {
            const items = factories.filter(item => category.factory_ids.includes(item.factory_id))

            return <FlexBox direction="column" width="100%" gy={10} >
                <TextBlock fontSize={20} lineHeight={20} fontWeight={700} style={{textAlign: "left"}}>{i18n.language === 'ru' ? category?.title_ru : category?.title_en}</TextBlock>
                <FlexBox direction="column" width="100%" gy={14}>
                    {items.map((item: any, index: number) => <MiningItem key={index} item={item} />)}
                </FlexBox>
            </FlexBox>
        })}
    </FlexBox>
}