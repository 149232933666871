import styled, {css} from "styled-components";

function exSize(s: string | number) {
    if (typeof s === 'number') return `${s}px`;
    return s;
}

export interface FlexBoxProps {
    width?: string | number;
    height?: string | number;
    flex?: string;
    justify?:
        | 'start'
        | 'normal'
        | 'space-between'
        | 'space-around'
        | 'center'
        | 'space-evenly'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | string;
    align?: 'normal' | 'flex-start' | 'flex-end' | 'center' | string;
    direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';

    offsetTop?: string | number;
    offsetBottom?: string | number;
    offsetLeft?: string | number;
    offsetRight?: string | number;
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | string;

    gx?: number | string;
    gy?: number | string;

    ox?: number | string;
    oy?: number | string;

}

export const FlexBox = styled.div<FlexBoxProps>`
    display: flex;
    flex: ${(p) => (p.flex ? p.flex : null)};
    flex-wrap: ${(p) => (p.wrap ? p.wrap : null)};
    justify-content: ${(p) => (p.justify ? p.justify : null)};
    flex-direction: ${(p) => (p.direction ? p.direction : 'row')};
    align-items: ${(p) => (p.align ? p.align : null)};

    > :not(:first-child) {
        margin-left: ${(p) => (p.gx ? exSize(p.gx) : null)};
        margin-top: ${(p) => (p.gy ? exSize(p.gy) : null)};
    }

    ${(p) =>
    p.width !== undefined
        ? css`
                  width: ${exSize(p.width)};
              `
        : null};
    ${(p) =>
    p.height !== undefined
        ? css`
                  height: ${exSize(p.height)};
              `
        : null};

    ${(p) =>
    p.ox
        ? css`
                  margin-right: ${exSize(p.ox)};
                  margin-left: ${exSize(p.ox)};
              `
        : null};
    ${(p) =>
    p.oy
        ? css`
                  margin-top: ${exSize(p.oy)};
                  margin-bottom: ${exSize(p.oy)};
              `
        : null};

    margin-top: ${(p) => (p.offsetTop ? exSize(p.offsetTop) : null)};
    margin-bottom: ${(p) => (p.offsetBottom ? exSize(p.offsetBottom) : null)};
    margin-left: ${(p) => (p.offsetLeft ? exSize(p.offsetLeft) : null)};
    margin-right: ${(p) => (p.offsetRight ? exSize(p.offsetRight) : null)};
    box-sizing: border-box;
`;
