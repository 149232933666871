import {AppContainer} from "./styles";
import {FC, ReactNode} from "react";
import {useGetNotification} from "../../shared/hooks/api/useGetNotification";
import {NotificationItem} from "../NotificationItem/NotificationItem";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";

export const AppWrapper: FC<{children: ReactNode, isSuperBackground?: boolean, isPaymentBackground?: boolean}> = ({children, isSuperBackground, isPaymentBackground}) => {

    return <AppContainer isSuperBackground={isSuperBackground} isPaymentBackground={isPaymentBackground}>
        {children}
        <NotificationItem/>
    </AppContainer>
}