import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {HoneyIcon} from "../../../shared/icons/HoneyIcon";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {useTranslation} from "react-i18next";
import {FC} from "react";

export const IncomePerHour: FC<{income: number}> = ({income}) => {

    const { t } = useTranslation()

    return <FlexBox gx={8} align="center">
        <HoneyIcon height={26} width={26}/>
        <TextBlock fontSize={18} lineHeight={18} fontWeight={700}>{income}/{t('mining.hour')}</TextBlock>
    </FlexBox>
}