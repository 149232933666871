import {FC} from "react";
import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {CheckBoxItemContainer} from "../styles";
import {AgreeIcon} from "../../../shared/icons/AgreeIcon";

export const CheckBox: FC<{ text?: string, value: boolean, onClick: () => void }> = ({text, value, onClick}) => {

    return <FlexBox align="center" gx={12} onClick={onClick}>
        <CheckBoxItemContainer active={value}>
            <AgreeIcon/>
        </CheckBoxItemContainer>
        <TextBlock fontSize={20} lineHeight={20}>{text}</TextBlock>
    </FlexBox>
}