import {useAppDispatch, useAppSelector} from "../storeHooks";
import {useEffect} from "react";
import {selectorAuth} from "../../slices/authSlice";
import {loadCardGame, loadCardHasBet, selectorCardGame} from "../../slices/cardGameSlice";
import {loadNotifications, selectorNotification} from "../../slices/notificationSlice";

export const useGetNotification = () => {

    const data = useAppSelector(selectorNotification)
    const auth = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (data.isLoading || !auth.token || data.error || data.isReady) return
        dispatch(loadNotifications({}))

    }, [data.isLoading, auth.token, data.error])

    return data
}