import React, {FC} from "react";
import {FriendAvatarWrapper, FriendItemContainer, FriendWalletItem} from "../styles";
import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {CoinIcon} from "../../../shared/icons/CoinIcon";
import {HoneyIcon} from "../../../shared/icons/HoneyIcon";
import {TonIcon} from "../../DepositPage/styles";

export const FriendItem: FC<{avatar?: string, nickName: string, notCoin: number, honey: number}> = ({ avatar, nickName, notCoin, honey }) => {

    return <FriendItemContainer>
        <FlexBox gx={4} align="center">
{/*            <FriendAvatarWrapper>
                <img src={avatar} alt=""/>
            </FriendAvatarWrapper>*/}
            <TextBlock fontSize={15} lineHeight={18} fontWeight={700}>@{nickName?.length > 6 ? nickName.slice(0, 6) + '...' : nickName}</TextBlock>
        </FlexBox>
        <FlexBox gx={8}>
            <FriendWalletItem>
                <TextBlock fontSize={13} lineHeight={16} fontWeight={600}>+{notCoin}</TextBlock>
                <TonIcon style={{position: 'initial', width: '17px', height: '17px'}}/>
            </FriendWalletItem>
            <FriendWalletItem>
                <TextBlock fontSize={13} lineHeight={16} fontWeight={600}>+{honey}</TextBlock>
                <HoneyIcon width={17} height={17}/>
            </FriendWalletItem>
        </FlexBox>
    </FriendItemContainer>
}