import {DefaultPageContainer} from "./styles";
import {FC, ReactNode} from "react";
import Navbar from "../Navbar";
import {Menu} from "../Menu/Menu";

export const DefaultPageWrapper: FC<{children: ReactNode, disableNavbar?: boolean, disableMenu?: boolean}> = ({children, disableNavbar, disableMenu}) => {

    return <DefaultPageContainer>
        {!disableNavbar && <Navbar/>}
        {children}
        {!disableMenu && <Menu/>}
    </DefaultPageContainer>
}