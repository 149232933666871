import styled from "styled-components";
import TonImage from "../../shared/images/TonImage.png";

export const DepositContainer = styled.div`
    display: flex;
`

export const TonIcon = styled.div`
    background: url(${TonImage}) no-repeat;
    background-size: 100% 100%;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 10px;
`