import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";
import {parseJwt} from "../actions/parseJWTToken";
import {collectRewards} from "./farmSlice";
import {collectTask} from "./tasksSlice";
import {WEB_APP_API} from "../const/WebAppApi";

interface AuthInitial {
    userName: string
    token: string
    link: string
    isLoading: boolean
    error: any
    isAdmin: boolean
    isActive: boolean
    bones: number
    notTokens: number
    memoTag: string
}

const initialState: AuthInitial = {
    userName: '',
    token: '',
    link: '',
    isAdmin: false,
    isActive: false,
    bones: 0,
    notTokens: 0,
    memoTag: '',
    isLoading: false,
    error: null
}

interface LoadUserResponse {
    username: string
    token: string
    link: string
    is_admin: boolean
    isActive: boolean
    bones: number
    not_tokens: number
}

const parseQueryString = (query: any) => {
    const params = {};
    const pairs = query.split('&');
    pairs.forEach((pair: any) => {
        const [key, value] = pair.split('=');
        // @ts-ignore
        params[key] = decodeURIComponent(value || '');
    });
    return params as any;
}

export const loadUser = createAsyncThunk<LoadUserResponse, { onBlock: () => void }>(
    'user/Load',
    async ({ onBlock }, thunkAPI) => {
        try {

            axios.interceptors.response.use(function (response) {
                // Просто возвращаем ответ, если статус не 423
                return response;
            }, function (error) {
                if (error?.status === 423) {
                    onBlock()
                }
                return Promise.reject(error);
            });

            const loginParams = new URLSearchParams();
            loginParams.append('init_data', WEB_APP_API?.initData || '');
            const response = await axios.post(API_ENDPOINT + 'users/login', loginParams, { headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });

            if (!response.data?.username) {
                return thunkAPI.rejectWithValue('Failed to load user');
            }

            axios.interceptors.request.use(function (config) {
                config.headers.Authorization = 'Bearer ' + response.data?.token || '';

                return config;
            });


            let responseLink: any = {}

            try {
                responseLink = await axios.get(API_ENDPOINT + 'users/generate-telegram-link', {});
            } catch (e) {
            }

            return {
                username: response.data?.username || '',
                isActive: response.data?.isActive || false,
                is_admin: response.data?.is_admin || false,
                bones: response.data?.bones || 0,
                not_tokens: response.data?.ton || 0,
                token: response.data?.token || '',
                link: responseLink?.data || '',
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load user')
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authorize(state, action) {
            state.userName = action.payload.userName
            state.isAdmin = action.payload.isAdmin
            state.bones = action.payload.bones
            state.notTokens = action.payload.notTokens
        },
        setHoney(state, action) {
            state.bones = action.payload
        },
        setTon(state, action) {
            state.notTokens = Math.round(action.payload * 100) / 100
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.userName = action.payload.username
                state.isAdmin = action.payload.is_admin
                state.isActive = action.payload.isActive
                state.bones = action.payload.bones
                state.notTokens = Math.round(action.payload.not_tokens * 100) / 100
                state.token = action.payload.token
                state.link = action.payload.link
                state.memoTag = parseJwt(action.payload.token)?.sub || ''
            })
            .addCase(loadUser.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(collectRewards.fulfilled, (state, action) => {
                state.bones = action.payload.rewardsCount + state.bones
            })
            .addCase(collectTask.fulfilled, (state, action) => {
                state.bones = action.payload.bonus + state.bones
            })
    }
})

export const { authorize, setHoney, setTon } = authSlice.actions
export const selectorAuth = (state: any): AuthInitial => state.auth
export default authSlice