import {useAppDispatch, useAppSelector} from "../storeHooks";
import {useEffect, useMemo, useState} from "react";
import {selectorAuth} from "../../slices/authSlice";
import {Factory, loadFactory, selectorFarm, UserFactory} from "../../slices/farmSlice";
import {loadTasks, selectorTasks, setIsReadyTasks} from "../../slices/tasksSlice";

export const useGetTasks = () => {

    const data = useAppSelector(selectorTasks)
    const auth = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (data.isLoading || data.isReady || !auth.token || data.error) return
        dispatch(loadTasks({}))

    }, [data.isLoading, data.isReady, auth.token, data.error])

    const forceUpdate = () => {
        dispatch(setIsReadyTasks(false))
    }

    return {
        forceUpdate,
        ...data
    }
}