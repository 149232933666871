import React, {FC} from "react";
import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {
    CardArrowContainer,
    CardCenter,
    CardLeft,
    CardLineContainer,
    CardNumber,
    CardRight,
    CardTitle
} from "../../styles";
import {ArrowLeftIcon} from "../../../../shared/icons/ArrowLeftIcon";
import {ArrowRightIcon} from "../../../../shared/icons/ArrowRightIcon";
import {GameCard} from "../../../../shared/slices/cardGameSlice";
import {useTranslation} from "react-i18next";

export const CardsSlider: FC<{active: number, cards: GameCard[], setActive: (val: number) => void, bettedCardId: string}> = ({active, cards, setActive, bettedCardId}) => {

    const isFirstActive = active === 1;
    const cardUrls = cards.map((card) => card.image_url);
    const isLastActive = active === cardUrls?.length;

    const firstIndex = isFirstActive ? cardUrls?.length - 1 : active - 2
    const avgIndex = active - 1
    const lastIndex = isLastActive ? 0 : active

    const { i18n } = useTranslation()

    return <FlexBox width='100vw' style={{position: 'relative'}} align="center" justify="center">
        {!bettedCardId && <CardArrowContainer style={{paddingLeft: '15px'}} onClick={() => setActive(isFirstActive ? cardUrls?.length : active - 1)}>
            <ArrowLeftIcon/>
        </CardArrowContainer>}
        <FlexBox width='316px' justify="center" style={{position: 'relative'}}>
            {!bettedCardId && <CardLeft>
                <CardTitle><CardNumber>{firstIndex + 1}</CardNumber><span>{i18n.language === 'ru' ? cards[firstIndex]?.description_ru : cards[firstIndex]?.description_en}</span></CardTitle>
                <img src={cardUrls?.[firstIndex] || ""} alt="card"/></CardLeft>}
            <CardCenter>
                <CardTitle><CardNumber>{avgIndex + 1}</CardNumber><span>{i18n.language === 'ru' ? cards[avgIndex]?.description_ru : cards[avgIndex]?.description_en}</span></CardTitle>
                <img src={cardUrls?.[avgIndex] || ""} alt="card"/>
            </CardCenter>
            {!bettedCardId && <CardRight>
                <CardTitle><CardNumber>{lastIndex + 1}</CardNumber><span>{i18n.language === 'ru' ? cards[lastIndex]?.description_ru : cards[lastIndex]?.description_en}</span></CardTitle>
                <img src={cardUrls?.[lastIndex] || ""} alt="card"/>
            </CardRight>}
        </FlexBox>
        {!bettedCardId && <CardArrowContainer style={{paddingRight: '15px'}} onClick={() => setActive(isLastActive ? 1 : active + 1)}>
            <ArrowRightIcon/>
        </CardArrowContainer>}
    </FlexBox>
}