import styled from "styled-components";

export const ReferralsPageContainer = styled.div`
    
`

export const EarnedBlockContainer = styled.div`
    background: #1F1930;
    border-radius: 15px;
    width: 100%;
    height: 113px;
    padding: 13px 5px 20px;
    box-sizing: border-box;
`

export const InviteBlockContainer = styled.div`
    background: #189BFF;
    border-radius: 15px;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CopyBlockContainer = styled.div`
    background: #189BFF;
    border-radius: 15px;
    width: 46px;
    min-width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        rect {
            stroke: #189BFF;
        }
    }
`

export const FriendItemContainer = styled.div`
    background: #1F1930;
    border-radius: 15px;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    box-sizing: border-box;
    padding: 9px;
`

export const FriendAvatarWrapper = styled.div`
    background: #29294E;
    border-radius: 5px;
    width: 28px;
    height: 28px;
`

export const FriendWalletItem = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
    background: #29294E;
    border-radius: 5px;
    width: 90px;
    height: 34px;
    
    box-sizing: border-box;
    padding: 0 8px;
`