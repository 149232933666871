import styled from "styled-components";

export const MenuWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
    z-index: 1000;
    
    display: flex;
    justify-content: center;
`

export const MenuContainer = styled.div`
    background: #29294E;
    border-radius: 15px;
    height: 66px;
    width: 313px;
    padding: 7px 5px;
    box-sizing: border-box;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const MenuItemContainer = styled.div<{active?: boolean, disabled?: boolean}>`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    
    border-radius: 15px;
    background: ${({active}) => active ? "#1A1629" : "none"};

    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    
    height: 56px;
    width: 55px;

    color: ${({disabled}) => disabled ? "#909090" : "#FFFFFF"};
    svg {
        path {
            fill: ${({disabled}) => disabled ? "#909090" : "#FFFFFF"}
        }   
    }
`