import {FC} from "react";
import {QuestionBlockContainer} from "../styles";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {QuestionIcon} from "../../../shared/icons/QuestionIcon";
import {useTranslation} from "react-i18next";

export const QuestionBlock: FC<{ description?: string }> = ({ description }) => {

    const { i18n } = useTranslation()

    const learnMorePage = i18n.language === 'ru' ? 'https://t.me/Fluffy_Infinity' : 'https://t.me/Fluffy_Infinity_ENG'

    return <QuestionBlockContainer>
        <TextBlock fontSize={17} lineHeight={16} fontWeight={500}>{description}</TextBlock>
        <div onClick={() => window.open(learnMorePage, '_self')}><QuestionIcon/></div>
    </QuestionBlockContainer>
}