import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";

export interface Task {
    rule_id: number,
    description_ru: string,
    description_en: string,
    bonus_amount: number,
    last_awarded_at: number,
    status: 'in_progress' | 'completed' | 'collected',
    frequency: 'once' | 'daily',
}


interface taskInitial {
    isLoading: boolean
    isReady: boolean
    tasks: Task[]
    error: any
}

const initialState: taskInitial = {
    isLoading: false,
    isReady: false,
    error: null,
    tasks: []
}

interface LoadTaskResponse {
    tasks: Task[]
}

export const loadTasks = createAsyncThunk<LoadTaskResponse, {}>(
    'tasks/Load',
    async ({}, thunkAPI) => {
        try {

            const response = await axios.get(API_ENDPOINT + 'awards-api/user-awards', {}) as any

            return {
                tasks: response?.data || [],
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load awards')
        }
    }
)

export const collectTask = createAsyncThunk<{ bonus: number }, { ruleId: number }>(
    'task/collect',
    async ({ ruleId }, thunkAPI) => {
        try {

            const response = await axios.post(API_ENDPOINT + 'awards-api/user-awards/collect/' + ruleId, {}) as any

            if (!response?.data?.rule_id) return thunkAPI.rejectWithValue('Failed to collect task')

            return {
                bonus: response?.data?.bonus || 0
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to collect task')
        }
    }
)

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setIsReadyTasks(state, action) {
            state.isReady = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadTasks.fulfilled, (state, action) => {
                state.isLoading = false
                state.isReady = true
                state.tasks = action.payload.tasks
            })
            .addCase(loadTasks.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(loadTasks.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(collectTask.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(collectTask.pending, (state, action) => {
                state.isLoading = true
                const index = state.tasks.findIndex(task => task.rule_id === action.meta.arg.ruleId)
                if (index === -1) return;

                state.tasks[index] = {
                    ...state.tasks[index],
                    status: 'collected'
                }
            })
            .addCase(collectTask.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { setIsReadyTasks } = tasksSlice.actions
export const selectorTasks = (state: any): taskInitial => state.tasks
export default tasksSlice