import {EarnedBlockContainer} from "../styles";
import React, {FC} from "react";
import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {CoinIcon} from "../../../shared/icons/CoinIcon";
import {HoneyIcon} from "../../../shared/icons/HoneyIcon";
import {useTranslation} from "react-i18next";
import {TonIcon} from "../../DepositPage/styles";

export const EarnedBlock: FC<{ coinCount: number, honeyCount: number }> = ({ coinCount, honeyCount }) => {

    const { t } = useTranslation()

    return <EarnedBlockContainer>
        <FlexBox justify="space-between" align="center" direction="column" height="100%">
            <TextBlock fontSize={20} lineHeight={16} fontWeight={700}>{t('referrals.full_income')}</TextBlock>
            <FlexBox width="calc(100% - 24px)" style={{maxWidth: '300px'}} justify="space-between" offsetTop={8}>
                <FlexBox align="center">
                    <TonIcon style={{position: 'initial', width: '45px', height: '45px', margin: '0 8px 0px 0'}}/>
                    <FlexBox direction="column" justify="center" offsetTop={8}>
                        <TextBlock fontSize={20} lineHeight={16} fontWeight={600}>{coinCount}</TextBlock>
                        <TextBlock fontSize={14} lineHeight={16} fontWeight={500}>TON</TextBlock>
                    </FlexBox>
                </FlexBox>
                <FlexBox align="center" gx={8}>
                    <HoneyIcon width={45} height={45}/>
                    <FlexBox direction="column" justify="center" offsetTop={8}>
                        <TextBlock fontSize={20} lineHeight={16} fontWeight={600}>{honeyCount}</TextBlock>
                        <TextBlock fontSize={14} lineHeight={16} fontWeight={500}>HONEY</TextBlock>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </EarnedBlockContainer>
}