import {CopyBlockContainer} from "../styles";
import React, {FC} from "react";
import {CopyIcon} from "../../../shared/icons/CopyIcon";
import {copyText} from "../../../shared/actions/copyText";
import {useTranslation} from "react-i18next";

export const CopyBlock: FC<{text: string}> = ({ text }) => {

    const { t } = useTranslation()

    return <CopyBlockContainer onClick={() => copyText(text, t('share.copied'))}>
        <CopyIcon/>
    </CopyBlockContainer>
}