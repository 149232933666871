import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";
import {parseJwt} from "../actions/parseJWTToken";

export interface CoinWallets {
    bones: number,
    tonTokens: number
}

export interface ReferralsUser {
    referral_id: number,
    referral_username: string,
    brought_in_bonus: CoinWallets
}

interface ReferralsInitial {
    totalTon: number,
    totalBones: number,
    referrals: ReferralsUser[],
    isReady: boolean,
    isLoading: boolean,
    error: any
}

const initialState: ReferralsInitial = {
    totalTon: 0,
    totalBones: 0,
    referrals: [],
    isReady: false,
    isLoading: false,
    error: null
}

interface LoadReferralsResponse {
    totalTon: number,
    totalBones: number,
    referrals: {
        referral_id: number,
        referral_username: string,
        brought_in_bonus: CoinWallets
    }[]
}


export const loadReferrals = createAsyncThunk<LoadReferralsResponse, {}>(
    'referrals/Load',
    async ({}, thunkAPI) => {
        try {
            const response = await axios.get(API_ENDPOINT + 'users/referrals', {});

            return {
                totalTon: response.data?.totalTon || 0,
                totalBones: response.data?.totalBones || 0,
                referrals: response.data?.referrals || []
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load referrals')
        }
    }
)

const referralsSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadReferrals.fulfilled, (state, action) => {
                state.isLoading = false
                state.isReady = true
                state.referrals = action.payload.referrals
                state.totalTon = action.payload.totalTon
                state.totalBones = action.payload.totalBones
            })
            .addCase(loadReferrals.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(loadReferrals.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const {  } = referralsSlice.actions
export const selectorReferrals = (state: any): ReferralsInitial => state.referrals
export default referralsSlice