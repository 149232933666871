import {LeftItemWrapper} from "../styles";
import {FC} from "react";
import {TonIcon} from "../../../pages/DepositPage/styles";

export const NavbarLeftItem: FC<{count: number}> = ({count}) => {

    return <LeftItemWrapper>
        <TonIcon style={{position: 'initial', marginRight: '4px', width: '20px', height: '20px'}}/>
        <span>{count}</span>
    </LeftItemWrapper>
}