import styled from "styled-components";
import {WEB_APP_API} from "../../shared/const/WebAppApi";

export const NotificationScreenContainer = styled.div`
    position: fixed;
    bottom: 0;
    height: 100vh;
    min-height: ${WEB_APP_API?.viewportHeight}px;
    z-index: 999999;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    background: rgba(0,0,0,0.65);
`;

export const NotificationItemContainer = styled.div`
    border-radius: 35px 35px 0 0;
    background: radial-gradient(69.19% 32.48% at 50% -6.3%, #0054DF 0%, #181818 100%);
    width: calc(100vw - 32px);
    height: 343px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NotificationItemWallet = styled.div`
    border-radius: 18px;
    width: 100%;
    height: 92px;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 16px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    
    background: #1C1C1E;
`;

export const NotificationItemWalletIconWrapper  = styled.div`
    position: absolute;
    top: -18px;
`