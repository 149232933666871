import {FC} from "react";

export const QuestionIcon: FC<{ width?: number, height?: number, fill?: string}> = ({width, height, fill}) => {
    return <svg width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
            stroke={fill || '#4BA4FF'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M9.6499 11.2769H8.23438C8.23861 10.8833 8.27246 10.5532 8.33594 10.2866C8.39941 10.0158 8.50521 9.77035 8.65332 9.55029C8.80566 9.33024 9.00667 9.09749 9.25635 8.85205C9.45101 8.66585 9.62663 8.49023 9.7832 8.3252C9.93978 8.15592 10.0646 7.97819 10.1577 7.79199C10.2508 7.60156 10.2974 7.38363 10.2974 7.13818C10.2974 6.87158 10.2529 6.64518 10.1641 6.45898C10.0752 6.27279 9.94401 6.13102 9.77051 6.03369C9.60124 5.93636 9.38965 5.8877 9.13574 5.8877C8.92415 5.8877 8.72526 5.93001 8.53906 6.01465C8.35286 6.09505 8.20264 6.22201 8.08838 6.39551C7.97412 6.56478 7.91276 6.78906 7.9043 7.06836H6.37451C6.38298 6.53516 6.50993 6.08659 6.75537 5.72266C7.00081 5.35872 7.33089 5.08577 7.74561 4.90381C8.16032 4.72184 8.6237 4.63086 9.13574 4.63086C9.7028 4.63086 10.1873 4.72819 10.5894 4.92285C10.9914 5.11328 11.2982 5.39258 11.5098 5.76074C11.7256 6.12467 11.8335 6.56478 11.8335 7.08105C11.8335 7.45345 11.7594 7.79199 11.6113 8.09668C11.4632 8.39714 11.2707 8.67855 11.0337 8.94092C10.7967 9.19906 10.5407 9.45719 10.2656 9.71533C10.0286 9.93115 9.86784 10.166 9.7832 10.4199C9.69857 10.6696 9.65413 10.9552 9.6499 11.2769ZM8.10742 13.2573C8.10742 13.0288 8.18571 12.8363 8.34229 12.6797C8.49886 12.5189 8.71257 12.4385 8.9834 12.4385C9.25423 12.4385 9.46794 12.5189 9.62451 12.6797C9.78109 12.8363 9.85938 13.0288 9.85938 13.2573C9.85938 13.4858 9.78109 13.6805 9.62451 13.8413C9.46794 13.9979 9.25423 14.0762 8.9834 14.0762C8.71257 14.0762 8.49886 13.9979 8.34229 13.8413C8.18571 13.6805 8.10742 13.4858 8.10742 13.2573Z"
            fill={fill || '#4BA4FF'}/>
    </svg>

}