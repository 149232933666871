import {
    RequestModalWrapper
} from "./styles";
import React, {FC, useState} from "react";
import Modal from 'react-modal';
import {TextBlock} from "../../components/Text/TextBlock";
import {FlexBox} from "../../components/FlexBox/FlexBox";
import {useTranslation} from "react-i18next";
import {ProfileButton} from "../../../pages/SettingsPage/styles";

const customStyles = {
    overlay: {
      zIndex: 999999999,
      background: 'rgba(41, 38, 58, .75)',
},
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        background: 'none',
        border: 'none'
    },
};

export const RequestModalSend: FC<{closeModal: () => void, isOpen: boolean}> = ({closeModal, isOpen}) => {

    const { t } = useTranslation()

    return <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <RequestModalWrapper>
            <TextBlock fontSize={28} lineHeight={28} fontWeight={700} style={{textAlign: "center"}}>{t('request.title')}</TextBlock>
            <FlexBox offsetTop={20} >
                <TextBlock fontSize={14} lineHeight={18} fontWeight={700} color="#FFFFFFBF" style={{textAlign: "center"}}>{t('request.send_funds')}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={64} width="100%">
                <ProfileButton  width="100%" blue onClick={closeModal}>
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={700}>{t('request.button')}</TextBlock>
                </ProfileButton>
            </FlexBox>
        </RequestModalWrapper>
    </Modal>
}