import styled from "styled-components";
import {WEB_APP_API} from "../../shared/const/WebAppApi";

export const EmptyScreenContainer = styled.div`
    background: #000000;
    height: 100%;
    min-height: ${WEB_APP_API?.viewportHeight}px;
    overflow: hidden;
    
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #ffffff;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
`