import {NavbarWrapper} from "./styles";
import {NavbarFrontBG} from "./components/NavbarFrontBG";
import {NavbarRightItem} from "./components/NavbarRightItem";
import {NavbarLeftItem} from "./components/NavbarLeftItem";
import {NavbarAccount} from "./components/NavbarAccount";
import {useAppSelector} from "../../shared/hooks/storeHooks";
import {selectorAuth} from "../../shared/slices/authSlice";
import {WEB_APP_API} from "../../shared/const/WebAppApi";

export const Navbar = () => {

    const { bones, notTokens, userName } = useAppSelector(selectorAuth)

    return <NavbarWrapper>
        <NavbarFrontBG/>
        <NavbarAccount avatar={WEB_APP_API.initDataUnsafe?.user?.photo_url || "https://img.freepik.com/free-photo/beautiful-kitten-with-colorful-clouds_23-2150752964.jpg"} nickName={userName}/>
        <NavbarRightItem count={bones}/>
        <NavbarLeftItem count={notTokens}/>
    </NavbarWrapper>
}