import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";

export interface GameCard {
    id: string,
    image_url: string,
    description_ru: string,
    description_en: string,
}

export interface GameInfo {
    round: {
        id: number,
        description_ru: string,
        description_en: string,
        end_date: string,
        target: 'min' | 'max' | 'avg'
    },
    cards: GameCard[]
}

interface CardGameInitial {
    isLoading: boolean
    error: any
    game: GameInfo
    hasUserBet: boolean
    bettedCardId: string
    currentBank: number
}

const initialState: CardGameInitial = {
    isLoading: false,
    error: null,
    game: {
        round: {
            id: 0,
            description_ru: '',
            description_en: '',
            target: 'min',
            end_date: ''
        },
        cards: []
    },
    bettedCardId: '',
    hasUserBet: false,
    currentBank: 0
}

interface LoadCardGameResponse {
    currentBank: number
    gameInfo: GameInfo
}
interface LoadHasBetResponse {
    hasUserBet: boolean
    bettedCardId: string
}


export const loadCardGame = createAsyncThunk<LoadCardGameResponse, {}>(
    'cardGame/Load',
    async ({}, thunkAPI) => {
        try {
            const requests = [
                await axios.get(API_ENDPOINT + 'game/current-game', {}),
                await axios.get(API_ENDPOINT + 'game/current-bank', {}),
            ];

            const responses = await Promise.all(requests);

            // Получение данных из ответов
            const [currentGame, currentBank] = responses.map(response => response.data);

            return {
                currentBank: currentBank?.total_bank || 0,
                gameInfo: currentGame
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load cardGame')
        }
    }
)

export const loadCardHasBet = createAsyncThunk<LoadHasBetResponse, {}>(
    'cardGame/LoadHasBet',
    async ({}, thunkAPI) => {
        try {

            // Получение данных из ответов
            const hasUserBet = await axios.get(API_ENDPOINT + 'users/has-user-bet', {}) as any

            return {
                hasUserBet: hasUserBet?.data?.hasBet || false,
                bettedCardId: hasUserBet?.data?.cardId || '',
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load cardGame')
        }
    }
)

const cardGameSlice = createSlice({
    name: 'cardGame',
    initialState,
    reducers: {
        setHasUserBet: (state, action) => {
            state.hasUserBet = action.payload.hasUserBet
            state.bettedCardId = action.payload.bettedCardId
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCardGame.fulfilled, (state, action) => {
                state.isLoading = false
                state.game = action.payload.gameInfo
                state.currentBank = action.payload.currentBank
            })
            .addCase(loadCardGame.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(loadCardGame.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(loadCardHasBet.fulfilled, (state, action) => {
                state.hasUserBet = action.payload.hasUserBet
                state.bettedCardId = action.payload.bettedCardId
            })
            .addCase(loadCardHasBet.pending, (state) => {

            })
            .addCase(loadCardHasBet.rejected, (state, action) => {
            })
    }
})

export const { setHasUserBet } = cardGameSlice.actions
export const selectorCardGame = (state: any): CardGameInitial => state.cardGame
export default cardGameSlice