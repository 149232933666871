import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";

export interface Notification {
    id: number,
    status: string,
    user_id: number,
    round_id: number,
    card_1_procent: number,
    card_2_procent: number,
    card_3_procent: number,
    total_bank: number,
    bones_win: number,
    tones_win: number
}

interface notificationInitial {
    isLoading: boolean
    isReady: boolean
    notification: Notification | null
    error: any
}

const initialState: notificationInitial = {
    isLoading: false,
    isReady: false,
    error: null,
    notification: null
/*    notification: {
        id: 15,
        status: 'pending',
        user_id: 433,
        round_id: 4,
        card_1_procent: 24,
        card_2_procent: 24,
        card_3_procent: 24,
        total_bank: 25000,
        bones_win: -3432,
        tones_win: 25.15
    }*/
}

interface LoadNotificationsResponse {
    notification: Notification | null
}

export const loadNotifications = createAsyncThunk<LoadNotificationsResponse, {}>(
    'notifications/Load',
    async ({}, thunkAPI) => {
        try {
            const request = await axios.get(API_ENDPOINT + 'ntf/notifications', {})

            return {
                notification: request?.data?.id ? request.data : null,
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load notifications')
        }
    }
)


export const confirmNotification = createAsyncThunk<{ notificationId: number  }, { notificationId: number }>(
    'notification/Confirm',
    async ({notificationId}, thunkAPI) => {
        try {
            const response = await axios.post(API_ENDPOINT + `ntf/${notificationId}/confirm`, {}) as any

            if (!response?.data) return thunkAPI.rejectWithValue('Failed to confirm notification')

            return {
                notificationId: notificationId
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to confirm notification')
        }
    }
)

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadNotifications.fulfilled, (state, action) => {
                state.isLoading = false
                state.isReady = true
                state.notification = action.payload.notification
            })
            .addCase(loadNotifications.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(loadNotifications.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(confirmNotification.fulfilled, (state, action) => {

                state.notification = null
            })
            .addCase(confirmNotification.pending, (state, action) => {

                state.notification = null
            })
            .addCase(confirmNotification.rejected, (state, action) => {
            })
    }
})

export const {} = notificationSlice.actions
export const selectorNotification = (state: any): notificationInitial => state.notification
export default notificationSlice