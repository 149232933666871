import styled from "styled-components";

export const DefaultPageContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
    position: relative;
    
    gap: 22px;
`