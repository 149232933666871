import {FC, useEffect, useMemo, useState} from "react";
import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {CardsSelectorLines} from "./CardsSelectorLines";
import {CardBottomBackground, CardChooseButton, CardMainBackground, CardMainPart} from "../../styles";
import {TextBlock} from "../../../../shared/components/Text/TextBlock";
import {CardsSlider} from "./CardsSlider";
import {ChargeToCardModal} from "../../../../shared/modals/ChargeToCardModal/ChargeToCardModal";
import {useTranslation} from "react-i18next";
import {GameInfo} from "../../../../shared/slices/cardGameSlice";
import {API_ENDPOINT, IMAGE_ENDPOINT} from "../../../../shared/const/endpoint";

export const CardsSelectorBlock: FC<{hasUserBet: boolean, bettedCardId: string, gameInfo: GameInfo}> = ({hasUserBet, bettedCardId, gameInfo}) => {

    const [active, setActive] = useState(2);
    const [isChargeModalOpen, setIsChargeModalOpen] = useState(false);
    const { t } = useTranslation()

    const activeWayIndex = gameInfo.round.target === 'min' ? 1 : gameInfo.round.target === 'avg' ? 2 : 3
    const activeWayTitle = gameInfo.round.target === 'min' ? 'unpopular' : gameInfo.round.target === 'avg' ? 'middle' : 'popular'

    const hardCodeCards = useMemo(() => {
        const imgs =  {
            "static/uploads/cntrlc.png": 'https://trikky.ru/wp-content/blogs.dir/1/files/2023/03/23/zyro-image-11.jpg',
            "static/uploads/cheif-kief.png": 'https://i.pinimg.com/originals/24/15/21/24152197af38deb718eb730992d441d0.webp',
            "static/uploads/hizenberg.png": 'https://i.pinimg.com/originals/5b/6e/ca/5b6eca63605bea0eeb48db43f77fa0ce.jpg',
        }

        // хардкод
/*        if (gameInfo.cards.length === 0) {
            return [
                {
                    description_ru: 'вотак 1u yg yug yug yu u gyu ',
                    description_en: 'votak 1',
                    id: 'card1',
                    image_url: 'https://trikky.ru/wp-content/blogs.dir/1/files/2023/03/23/zyro-image-11.jpg'
                },
                {
                    description_ru: 'вотак 2',
                    description_en: 'votak 2',
                    id: 'card2',
                    image_url: 'https://i.pinimg.com/originals/24/15/21/24152197af38deb718eb730992d441d0.webp'
                },
                {
                    description_ru: 'вотак 3',
                    description_en: 'votak 3',
                    id: 'card3',
                    image_url: 'https://i.pinimg.com/originals/5b/6e/ca/5b6eca63605bea0eeb48db43f77fa0ce.jpg'
                },
            ]
        }*/
        return gameInfo.cards.map((card, index) => {
            // @ts-ignore
            // return {...card, image_url: (imgs?.[card?.image_url] || imgs["static/uploads/hizenberg.png"])}
            return {...card, image_url: IMAGE_ENDPOINT + card?.image_url}
        })
    }, [gameInfo])

    const activeCard = hardCodeCards?.[active - 1]?.id || '';
    
    useEffect(() => {
        if (bettedCardId) {
            // const bettedCardIndex = gameInfo.cards?.findIndex((card) => card.id === bettedCardId)
            const bettedCardIndex = hardCodeCards?.findIndex((card) => card.id === bettedCardId)
            setActive(bettedCardIndex === -1 ? 1 : bettedCardIndex + 1);
        }
    }, [bettedCardId, hardCodeCards]);

    if (hardCodeCards?.length < 3) return null

    return <FlexBox width="100%" direction="column"  justify="space-between">
        <CardsSelectorLines active={activeWayIndex}/>
        <FlexBox width="100%" offsetTop={6}>
            <CardMainPart>
                <FlexBox direction="column" width="100%" align="center" style={{zIndex: 2}}>
                    <FlexBox offsetTop={5}>
                        <TextBlock fontSize={12} lineHeight={12}>{t('cards.find_way.' + activeWayTitle)}</TextBlock>
                    </FlexBox>
                    <FlexBox offsetTop={30} justify="center">
                        {/*<CardsSlider setActive={setActive} active={active} cardUrls={gameInfo.cards.map(card => card?.image_url || "")} bettedCardId={bettedCardId}/>*/}
                        <CardsSlider setActive={setActive} active={active} cards={hardCodeCards} bettedCardId={bettedCardId}/>
                    </FlexBox>
                    {bettedCardId && <FlexBox offsetTop={4} justify="center"><TextBlock fontSize={15} lineHeight={15} fontWeight={600}>{t('cards.choice_done')}</TextBlock></FlexBox>}
                    <FlexBox offsetTop={15} justify="center">
                        <CardChooseButton onClick={() => setIsChargeModalOpen(true)}><TextBlock fontSize={16} lineHeight={16} fontWeight={700}>{t(bettedCardId ? 'cards.place_ton' : 'cards.place')}</TextBlock></CardChooseButton>
                        <ChargeToCardModal activeCard={activeCard} activeIndex={active} isOpen={isChargeModalOpen} alreadyHasBet={Boolean(bettedCardId)} closeModal={() => setIsChargeModalOpen(false)}/>
                    </FlexBox>
                </FlexBox>
                <CardMainBackground/>
                <CardBottomBackground/>
            </CardMainPart>
        </FlexBox>
    </FlexBox>
}