import {WEB_APP_API} from "../const/WebAppApi";

export const shareMessage = (text: string) => {
    if (!WEB_APP_API?.shareText) return;
    WEB_APP_API.shareText(text);
}

export const generateShareLink = (text: string, url: string) => {
    return `https://t.me/share/url?url=${url}&text=${text}`
}
