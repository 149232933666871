import React, {FC} from "react";
import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {CardLineContainer} from "../../styles";

export const CardsSelectorLines: FC<{active: number}> = ({active}) => {

    return <FlexBox width='100%' gx={7}>
        <CardLineContainer position="left" active={active === 1}/>
        <CardLineContainer position="center" active={active === 2}/>
        <CardLineContainer position="right" active={active === 3}/>
    </FlexBox>
}