import {InviteBlockContainer} from "../styles";
import React, {FC} from "react";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {useTranslation} from "react-i18next";
import {generateShareLink, shareMessage} from "../../../shared/actions/shareMessage";
import {useAppSelector} from "../../../shared/hooks/storeHooks";
import {selectorAuth} from "../../../shared/slices/authSlice";

export const InviteBlock: FC = () => {

    const { t } = useTranslation()
    const { link } = useAppSelector(selectorAuth)

    return <a style={{textDecoration: "none", width: "100%"}} href={generateShareLink(t('share.text'), link)}><InviteBlockContainer>
        <TextBlock fontSize={16} lineHeight={18} fontWeight={700}>{t('referrals.invite_friend')}</TextBlock>
</InviteBlockContainer></a>
}