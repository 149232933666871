import {FC} from "react";
import {MenuItemContainer} from "../styles";
import {IMenuItem} from "../Menu";

export const MenuItem: FC<IMenuItem> = ({ icon, text, active, disabled, onClick}) => {

    const onClickHandler = () => {
        onClick && !active && !disabled && onClick();
    }

    return <MenuItemContainer disabled={disabled} active={active} onClick={onClickHandler}>
        {icon}
        <div>{text}</div>
    </MenuItemContainer>
}