import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @ts-ignore
ToastContainer.defaultProps = {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    theme: "dark",
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
};

export default ToastContainer;