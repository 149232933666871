import styled from "styled-components";
import CardLine from "../../shared/images/CardLine.png";
import CardLineActive from "../../shared/images/CardLineActive.png";
import CardsBackground from "../../shared/images/CardsBackground.png";

export const QuestionBlockContainer = styled.div`
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;

    background: radial-gradient(39.22% 167.44% at 53.7% 124.07%, #4A477E 0%, #0E0C19 100%);
    width: 100%;
    height: 40px;
    z-index: 3;
`
export const CardsInfoBlockContainer = styled.div`
    display: flex;
    gap: 64px;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 8px;

    background: radial-gradient(44.35% 73.21% at 54.14% 58.33%, rgba(36, 35, 79, 0.5625) 0%, rgba(11, 9, 20, 0.75) 100%);
    width: 100%;
    height: 40px;

    position: relative;
    top: 0px;
`
export const CardLineContainer = styled.div<{active?: boolean, position: 'left' | 'right' | 'center'}>`
    height: 29px;
    width: 100%;
    background: url(${({active}) => active ? CardLineActive : CardLine});
    background-size: 100% 100%;
    border-radius: ${({position}) => position === 'left' ? '0 7px 7px 0' : position === 'right' ? '7px 0 0 7px' : '7px 7px 7px 7px'};
    box-shadow: url(${({active}) => active ? '0px 4px 4px 0px #FFFFFF40 inset, 0px 5px 4px 0px #44802B40, 0px -5px 4px 0px #44802B40' : 'box-shadow: 0px 4px 4px 0px #FFFFFF40 inset'});
`

export const CardBottomBackground = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 431px;
    background: linear-gradient(180deg, #0C0A15 0%, #191930 100%);
`

export const CardMainBackground = styled.div`
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    height: 485px;
    background: url("${CardsBackground}") no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    max-width: 372px;
`

export const CardMainPart = styled.div`
    position: relative;
    height: 483px;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    
`


export const CardLeft = styled.div`
    width: 143px;
    min-width: 143px;
    height: 231px;
    border-radius: 10px;
    position: absolute;
    transform: rotate(-7.7deg) scale(0.846);
    left: 0;
    bottom: 0px;
    user-select: none;
    box-sizing: border-box;
    border: 2px #FFFFFF solid;
    background: #FFFFFF;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const CardCenter = styled.div`
    width: 143px;
    min-width: 143px;
    height: 231px;
    border-radius: 10px;
    z-index: 1;
    user-select: none;
    box-sizing: border-box;
    border: 2px #FFFFFF solid;
    position: relative;
    background: #FFFFFF;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const CardRight = styled.div`
    width: 143px;
    min-width: 143px;
    height: 231px;
    border-radius: 10px;
    position: absolute;
    transform: rotate(7.7deg) scale(0.846);
    right: 0px;
    bottom: 0px;
    user-select: none;
    box-sizing: border-box;
    border: 2px #FFFFFF solid;
    background: #FFFFFF;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const CardChooseButton = styled.div`
    border-radius: 10px;
    width: 302px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180.61deg, #189BFF 88.87%, rgba(146, 112, 191, 0.05) 174.85%);
    user-select: none
`

export const CardArrowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 60px;
    box-sizing: border-box;
`

export const CardTitle = styled.div`
    position: absolute;
    top: 6px;
    left: 6px;

    color: #1c1c1c;
    font-weight: 600;
    font-size: 11px;
    max-width: calc(100% - 6px);
    
    display: flex;
    align-items: flex-start;
    gap: 4px;
    text-align: left;
    
    span {
        text-transform: uppercase;
        margin-bottom: 3px;
        word-break: break-word;
    }
`

export const CardNumber = styled.div`
    min-width: 16px;
    height: 16px;
    width: 16px;
    
    background-color: #1c1c1c;
    color: #ffffff;
    
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 100%;

    box-sizing: border-box;
`