
export const parseJwt = (token: string) => {
    try {
        const base64Url = token.split('.')[1]; // Извлекаем полезную нагрузку (payload)
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Приводим строку к стандартному Base64
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload); // Парсим JSON и возвращаем объект
    } catch (e) {
        console.error("Invalid JWT token:", e);
        return null;
    }
}