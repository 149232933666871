import styled from "styled-components";
import emptyBackgroundImage from "../../shared/images/emptyBackgroundImage.png"

export const AppContainer = styled.div<{isSuperBackground?: boolean, isPaymentBackground?: boolean}>`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background: linear-gradient(1.56deg, #0C0A15 -2.75%, #191930 91.47%, rgba(0, 0, 0, 0.55) 100.23%),
    radial-gradient(727.36% 87.83% at 82.71% 12.17%, rgba(31, 60, 105, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);

    background: ${({isSuperBackground, isPaymentBackground}) => isPaymentBackground ? 'linear-gradient(1.56deg, #0C0A15 -2.75%, #191930 91.47%, rgba(0, 0, 0, 0.55) 175.23%), ' +
            'radial-gradient(727.36% 87.83% at 82.71% 12.17%, rgba(31, 60, 105, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)' 
            : isSuperBackground ? `url(${emptyBackgroundImage}) top center, #0B0914` : '#0B0914'};
    background-size: 100%;
    background-repeat: no-repeat;
`
