import {FC, useMemo} from "react";
import {TextBlock} from "../../../../shared/components/Text/TextBlock";
import {ProfileButton} from "../../../SettingsPage/styles";
import {HoneyIcon} from "../../../../shared/icons/HoneyIcon";
import {LevelContainer, MiningItemContainer} from "../../styles";
import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {buyFactory, Factory, upgradeFactory, UserFactory} from "../../../../shared/slices/farmSlice";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../shared/hooks/storeHooks";
import {selectorAuth, setHoney} from "../../../../shared/slices/authSlice";
import {DoneIcon} from "../../../../shared/icons/DoneIcon";

export const MiningItem: FC<{item: (Factory & UserFactory)}> = ({item}) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const { bones } = useAppSelector(selectorAuth)

    const isDisabled = useMemo(() => {
        if (item?.max_level <= item?.level_id) return true
        if (item?.isLoading) return true
        return bones < item?.next_level_cost
    }, [item, bones])

    const onUpgrade = () => {
        if (isDisabled) return;

        dispatch(setHoney(bones - item?.next_level_cost))
        dispatch(item?.level_id ? upgradeFactory({factoryId: item?.factory_id}) : buyFactory({factoryId: item?.factory_id}))
    }

    return <MiningItemContainer>
        <img src={item?.image_url} alt="task"/>
        <FlexBox style={{height: '100%'}} width="100%" direction="column" justify="space-between">
            <TextBlock fontSize={16} lineHeight={16} fontWeight={700} color="#FFFFFF" style={{textAlign: 'left'}}>{i18n.language === 'ru' ? item?.name_ru : item?.name_en}</TextBlock>
            <FlexBox gx={2}>
                <LevelContainer>{item?.level_id >= item?.max_level ? t('mining.max') : `${item?.level_id} ${t('mining.lvl')}`}</LevelContainer>
                <FlexBox gx={4} align="center">
                    <HoneyIcon width={22} height={22}/><TextBlock fontSize={16} lineHeight={16} fontWeight={500} color="#FFFFFF">+{item?.profit_per_hour}</TextBlock>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        {item?.max_level <= item?.level_id ? <FlexBox style={{width: '105px', minWidth: '105px'}} justify="center" align="center">
            <DoneIcon/>
        </FlexBox> : <ProfileButton onClick={onUpgrade} disabled={isDisabled} blue width={'105px'}>
            <HoneyIcon width={22} height={22}/><TextBlock fontSize={16} lineHeight={16} fontWeight={500} color="#FFFFFF">{item?.next_level_cost}</TextBlock>
        </ProfileButton>}
    </MiningItemContainer>
}