
export const FriendsIcon = () => {
    return <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33.4063 18.6129L30.3168 17.8017L30.08 16.8103C30.9 15.936 31.4653 14.7589 31.6495 13.5453C32.1263 13.374 32.4895 12.9286 32.5537 12.3793L32.7589 10.6495C32.8074 10.2505 32.6905 9.8471 32.4379 9.54426C32.3095 9.38842 32.1516 9.26684 31.9758 9.18395L32.0453 7.68632L32.3242 7.39232C32.8168 6.84189 33.2263 5.90242 32.3811 4.54847C31.7432 3.52058 30.6611 3 29.1674 3C28.5874 3 27.2589 3 26.0095 3.86763C22.4305 3.95716 21.9421 5.72226 21.9421 7.44537C21.9421 7.82116 22.0221 8.61805 22.0821 9.15411C21.8853 9.23479 21.7095 9.3641 21.5663 9.53432C21.3105 9.83826 21.1916 10.245 21.2389 10.6484L21.4442 12.3782C21.5137 12.9651 21.9221 13.4326 22.4474 13.5752C22.6316 14.7379 23.1663 15.8741 23.9368 16.7307L23.6821 17.8017L20.5926 18.6129C18.4779 19.1678 17 21.1562 17 23.4474C17 23.7524 17.2358 24 17.5263 24H36.4737C36.7642 24 37 23.7502 37 23.4452C37 21.1551 35.5221 19.1678 33.4063 18.6129Z"
            fill="white"/>
        <path
            d="M16.4063 18.6129L13.3168 17.8017L13.08 16.8103C13.9 15.936 14.4653 14.7589 14.6495 13.5453C15.1263 13.374 15.4895 12.9286 15.5537 12.3793L15.7589 10.6495C15.8074 10.2505 15.6905 9.8471 15.4379 9.54426C15.3095 9.38842 15.1516 9.26684 14.9758 9.18395L15.0453 7.68632L15.3242 7.39232C15.8168 6.84189 16.2263 5.90242 15.3811 4.54847C14.7432 3.52058 13.6611 3 12.1674 3C11.5874 3 10.2589 3 9.00947 3.86763C5.43053 3.95716 4.94211 5.72226 4.94211 7.44537C4.94211 7.82116 5.02211 8.61805 5.08211 9.15411C4.88526 9.23479 4.70947 9.3641 4.56632 9.53432C4.31053 9.83826 4.19158 10.245 4.23895 10.6484L4.44421 12.3782C4.51368 12.9651 4.92211 13.4326 5.44737 13.5752C5.63158 14.7379 6.16632 15.8741 6.93684 16.7307L6.68211 17.8017L3.59263 18.6129C1.47789 19.1678 0 21.1562 0 23.4474C0 23.7524 0.235789 24 0.526316 24H19.4737C19.7642 24 20 23.7502 20 23.4452C20 21.1551 18.5221 19.1678 16.4063 18.6129Z"
            fill="white"/>
        <path
            d="M25.0915 7.23584L25.0965 7.23057L25.1013 7.22516C25.4425 6.84453 25.7726 6.30927 25.8581 5.6168C25.9443 4.91874 25.7743 4.12162 25.2212 3.23674C24.3458 1.82874 22.8686 1.15527 20.9602 1.15527L20.95 1.15527C20.26 1.15525 18.6131 1.15519 17.0304 2.19424C14.9079 2.27084 13.571 2.84049 12.7797 3.76716C11.9796 4.70409 11.8342 5.89768 11.8342 6.95468C11.8342 7.35685 11.9036 8.10653 11.9677 8.70996C11.795 8.82286 11.6388 8.96181 11.5032 9.12284C11.1062 9.59399 10.926 10.2176 10.9982 10.8315L10.9983 10.8321L11.2433 12.894C11.3358 13.6742 11.8232 14.3284 12.4959 14.633C12.7518 15.9201 13.3404 17.165 14.1698 18.1576L13.9947 18.893L10.5965 19.7841C7.83777 20.5068 5.93408 23.0871 5.93408 26.031C5.93408 26.6482 6.4174 27.1898 7.06243 27.1898H29.6829C30.3304 27.1898 30.8112 26.6429 30.8112 26.0283C30.8112 23.0855 28.9073 20.5067 26.1475 19.7841L22.7491 18.893L22.5973 18.2585C23.4885 17.2337 24.1148 15.9292 24.3715 14.573C24.979 14.2474 25.4137 13.627 25.4995 12.8949C25.4995 12.8948 25.4995 12.8947 25.4996 12.8946L25.7444 10.8347C25.7444 10.8345 25.7444 10.8343 25.7444 10.8341C25.8182 10.2254 25.6408 9.6066 25.2493 9.13735C25.125 8.98692 24.9826 8.85658 24.8265 8.74828L24.8868 7.45125L25.0915 7.23584Z"
            fill="white" stroke="#29294E"/>
    </svg>

}