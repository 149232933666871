import {DefaultPageWrapper} from "../../components/DefaultPageWrapper/DefaultPageWrapper";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {useTranslation} from "react-i18next";
import React, {useMemo, useState} from "react";
import {IncomePerHour} from "./components/IncomePerHour";
import {ChargeToCardWalletSelector, ChargeToCardWalletSelectorItem} from "../../shared/modals/ChargeToCardModal/styles";
import {ButtonIcon} from "./components/ButtonIcon";
import {NavigationSwitcherContainer} from "./styles";
import {TaskList} from "./components/TaskList/TaskList";
import {MiningList} from "./components/MiningList/MiningList";
import {useGetFarm} from "../../shared/hooks/api/useGetFarm";
import {QuestionIcon} from "../../shared/icons/QuestionIcon";
import {RefreshIcon} from "../../shared/icons/RefreshIcon";
import {LoadIcon} from "../../shared/icons/LoadIcon";
import {HoneyIcon} from "../../shared/icons/HoneyIcon";
import {collectRewards, selectorFarm} from "../../shared/slices/farmSlice";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/storeHooks";
import {selectorAuth, setHoney} from "../../shared/slices/authSlice";
import {useGetTasks} from "../../shared/hooks/api/useGetTasks";
import {loadTasks} from "../../shared/slices/tasksSlice";

export const MiningPage = () => {

    const { t } = useTranslation()
    const [activePage, setActivePage] = useState<'mining' | 'tasks'>('mining')
    const {factories, categories: factoryCategories} = useGetFarm()
    const { tasks, isLoading: isLoadingTasks, error: errorTasks, forceUpdate } = useGetTasks()
    const totalIncome = useMemo(() => {
        return factories.reduce((sum, item) => sum + (item.profit_per_hour || 0), 0);
    }, [factories])
    const dispatch = useAppDispatch()
    const { bones } = useAppSelector(selectorAuth)

    const {availableRewards: {isLoading: isLoadingRewards, value: rewardsCount}} = useAppSelector(selectorFarm)

    const isRewardsCollectDisabled = isLoadingRewards || !rewardsCount
    const isRefreshDisabled = isLoadingTasks || errorTasks

    const onCollect = () => {
        if (isRewardsCollectDisabled) return;

        dispatch(collectRewards({}))
    }
    const onRefresh = () => {
        if (isRefreshDisabled) return;

        forceUpdate()
    }

    return <DefaultPageWrapper>
        <FlexBox justify="center" align="center" direction="column" offsetBottom={140}>
            <FlexBox offsetTop={24} direction="column" align="flex-start">
                <TextBlock fontSize={28} lineHeight={28} fontWeight={700}>{t(`${activePage}.title`)}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={14} direction="column" align="flex-start">
                <TextBlock fontSize={15} lineHeight={15} fontWeight={700} color="#CFCDCD">{t(`${activePage}.description`)}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={14} direction="column" align="flex-start">
                <IncomePerHour income={totalIncome}/>
            </FlexBox>
            <NavigationSwitcherContainer style={{marginLeft: '59px'}} offsetTop={14} gx={10}>
                <ChargeToCardWalletSelector width={'214px'} height={'49px'} isBlueStyle>
                    <ChargeToCardWalletSelectorItem onClick={() => setActivePage('mining')} position="left" active={activePage === 'mining'} isBlueStyle>
                        <TextBlock fontSize={17} lineHeight={17} fontWeight={500}>{t(`mining.name`)}</TextBlock>
                    </ChargeToCardWalletSelectorItem>
                    <ChargeToCardWalletSelectorItem onClick={() => setActivePage('tasks')} position="right" active={activePage === 'tasks'} isBlueStyle>
                        <TextBlock fontSize={17} lineHeight={17} fontWeight={500}>{t(`tasks.name`)}</TextBlock>
                    </ChargeToCardWalletSelectorItem>
                </ChargeToCardWalletSelector>
                <ButtonIcon disabled={activePage === 'tasks' ? isRefreshDisabled : isRewardsCollectDisabled} onClick={() => activePage === 'tasks' ? onRefresh() : onCollect()}>
                    {activePage === 'tasks' ? <RefreshIcon/> : <div style={{marginBottom: '0px', height: 'fit-content'}}><LoadIcon/></div>}
                    </ButtonIcon>
            </NavigationSwitcherContainer>
            {activePage === 'tasks' && <FlexBox style={{height: '40px'}} offsetTop={12} direction="column" align="flex-start">
                <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color="#CFCDCD"
                           style={{display: 'inline-block'}}>{t('tasks.refresh_hint_1')}
                    <div style={{display: 'inline-block', margin: '4px 4px 0 4px', verticalAlign: 'text-bottom'}}><RefreshIcon fill="#189BFF" width={12} height={12}/></div>
                    {t('tasks.refresh_hint_2')}
                </TextBlock>
            </FlexBox>}
            {activePage === 'mining' && <FlexBox style={{height: '40px'}} offsetTop={12} direction="column" align="center" gy={2}>
                <TextBlock fontSize={15} lineHeight={16} fontWeight={700} color="#CFCDCD"
                           style={{display: 'inline-block'}}><span>{t('tasks.mining_hint_1')}</span>
                    <div style={{display: 'inline-block', verticalAlign: 'bottom', margin: '0px 4px 0 4px', height: '16px'}}><LoadIcon fill="#189BFF" width={15}
                                                                                              height={15}/></div>
                    {t('tasks.mining_hint_2')}
                    <div style={{display: 'inline-block', margin: '0px 4px 0 4px', color: '#189BFF'}}>{rewardsCount} HONEY</div>
                </TextBlock>
                <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color="#CFCDCD"
                           style={{display: 'inline-block'}}>{t('tasks.mining_hint_3')}
                </TextBlock>
            </FlexBox>}
            {activePage === 'tasks' && <FlexBox offsetTop={16} width="calc(100% - 28px)">
                <TaskList tasks={tasks}/>
            </FlexBox>}
            {activePage === 'mining' && <FlexBox offsetTop={16} width="calc(100% - 28px)">
                <MiningList factories={factories} factoryCategories={factoryCategories} />
            </FlexBox>}
        </FlexBox>
    </DefaultPageWrapper>
}