import {FC} from "react";
import {CardsInfoBlockContainer, QuestionBlockContainer} from "../styles";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {QuestionIcon} from "../../../shared/icons/QuestionIcon";
import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {BankIcon} from "../../../shared/icons/BankIcon";
import {TimeIcon} from "../../../shared/icons/TimeIcon";
import {useTranslation} from "react-i18next";

const getHoursMinutes = (until: string) => {
    const targetTime = new Date(until);
    const currentTime = new Date();
    // @ts-ignore
    const diffMs = targetTime - currentTime;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    return [diffHours || 0, diffMinutes || 0]
}

const roundBankRound = (bankRound: number) => {
    if (bankRound <= 1000) return '1k';
    if (bankRound <= 9999) {
        return Math.floor(bankRound / 1000) + 'k';
    }
    if (bankRound <= 99999) {
        return (Math.floor(bankRound / 10000) * 10) + 'k';
    }
    if (bankRound <= 999999) {
        return (Math.floor(bankRound / 100000) * 100) + 'k';
    }
    return Math.floor(bankRound / 1000000) + 'M';
}

export const CardsInfoBlock: FC<{ bankRound: number, untilEnd: string }> = ({bankRound, untilEnd}) => {

    const { t } = useTranslation()

    const [hours, minutes] = getHoursMinutes(untilEnd)

    return <CardsInfoBlockContainer>
        <FlexBox align="center" gx={8}>
            <BankIcon/>
            <FlexBox direction="column" justify="center">
                <TextBlock fontSize={11.5} lineHeight={14} fontWeight={600}>{t('cards.bank_round')}</TextBlock>
                <TextBlock fontSize={14} lineHeight={16} fontWeight={500}>+{roundBankRound(bankRound)}</TextBlock>
            </FlexBox>
        </FlexBox>
        <FlexBox align="center" gx={8}>
            <TimeIcon/>
            <FlexBox direction="column" justify="center">
                <TextBlock fontSize={11.5} lineHeight={14} fontWeight={600}>{t('cards.until_end')}</TextBlock>
                <TextBlock fontSize={14} lineHeight={16} fontWeight={500}>{t('cards.until_end_time', {hours: hours, minutes: minutes})}</TextBlock>
            </FlexBox>
        </FlexBox>
    </CardsInfoBlockContainer>
}