import {useAppDispatch, useAppSelector} from "../storeHooks";
import {useEffect} from "react";
import {selectorAuth} from "../../slices/authSlice";
import {loadCardGame, loadCardHasBet, selectorCardGame} from "../../slices/cardGameSlice";

export const useGetGameInfo = () => {

    const data = useAppSelector(selectorCardGame)
    const auth = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (data.isLoading || data.game.round.id || !auth.token || data.error) return
        dispatch(loadCardGame({}))
        dispatch(loadCardHasBet({}))

    }, [data.isLoading, auth.token, data.error])

    return data
}